<template>
  <div>
    <div id="container-dashboard">
      <div id="tabs-wrapper">
        <TfTabs
          :value="$route.name"
          @tab-click="$router.push({
            name: $event.name,
            params
          })"
        >
          <ElTabPane
            v-for="dashboard in dashboards"
            :key="dashboard.label"
            :label="dashboard.label"
            :name="dashboard.name"
            :lazy="true"
          />
        </TfTabs>
      </div>
      <RouterView />
    </div>
    <footer>
      Powered By <ThreeflowWordmark />
    </footer>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import project from '@/mixins/project.js';

  /**
   * Parent Dashboard View for /dashboards.
   *
   * @exports Dashboards/Dashboards
   */

  export default {
    name: 'DashboardsContainer',
    mixins: [project],
    data: () => ({
      dashboards: [
        {
          label: 'Your opportunities',
          name: 'YourOpportunities',
        },
        {
          label: 'All opportunities',
          name: 'AllOpportunities',
        },
      ],
    }),
    computed: {
      ...mapState(useAccountStore, ['roleNames']),
      /**
       * Checks if user has the carrier_executive role
       *
       * @returns {boolean}
       */
      isCarrierExecutive() {
        return this.roleNames.includes('carrier_executive');
      },
      params() {
        return {
          pageSelected: 1,
          sortDirection: 'asc',
          sortProp: 'statusSortOrder,due_date',
        };
      },
    },
    watch: {
      /**
       * Watch the route params to fire updateRouteParams.
       * This is used in the scenario where a user comes to the dashboards w/o the prop params.
       */
      '$route.params': function () {
        this.updateRouteParams();
      },
    },
    /**
     * Replace the router params, reset any project data in the store,
     * add Insights if carrier_executive role
     */
    created() {
      this.updateRouteParams();
      this.resetProject();
      this.addInsights();
    },
    methods: {
      /**
       * Check the $route params and if there is any of there are any required params missing (pageSelected, sortDirection, sortProp),
       * replace them with the default params.
       */
      updateRouteParams() {
        const nonFilterParams = { ...this.$route.params };

        if (!nonFilterParams.filters) {
          delete nonFilterParams.filters;
        }

        if (!Object.values(nonFilterParams).includes(undefined)) {
          return;
        }

        this.$router.replace({ params: this.params });
      },
      /**
       * Adds insights if carrier_executive role
       */
      addInsights() {
        if (this.isCarrierExecutive) {
          this.dashboards.push({
            label: 'Insights',
            name: 'Insights',
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #container-dashboard {
    box-sizing: border-box;
    min-height: calc(100vh - 130px);
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  #tabs-wrapper {
    background: var(--tf-gray-light);
    padding: 47px 0 0;

    + div {
      padding: 0 25px;
    }

    :deep(.el-tabs__nav-wrap) {
      padding-left: 25px;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 0 13px;
    font-size: 10px;
    font-weight: bold;
    color: var(--tf-gray);
    text-transform: uppercase;

    svg {
      width: 71px;
      height: 16px;
      margin: 0 0 3px 6px;
    }
  }
</style>
