import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * All Smart Quote related submission calls
 *
 * @exports Submission
 */

/**
 * Submit renewal token to endpoint to transition the product(s) to completed
 *
 * @param {string} token
 * @param {string} quoteType
 * @returns {Promise}
 */
export async function submitQuote(token, quoteType = 'renewal') {
  const url = `${normalizedEndpoint}/projects/submit_${quoteType}`;
  const { data } = await axios
    .patch(
      url,
      { product_submission_token: token },
      axiosConfig(),
    );

  return data;
}
