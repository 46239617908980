<template>
  <div
    id="app"
    :class="['app', pageTypeClass]"
  >
    <template v-if="isAuthenticatedPage">
      <header>
        <GlobalNavigation />
      </header>
      <!-- Show Password Reset Modal -->
      <ElDialog
        v-if="accountIsLoaded && userInfo.temp_password"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
        class="reset-password-dialog"
        title="Update password"
        top="70px"
        data-test="password change"
        visible
      >
        <AppResetPasswordForm
          :password.sync="password"
          :is-valid-password.sync="isValidPassword"
        >
          <template #ctaSubmit>
            <AppButton
              :is-disabled="!isValidPassword"
              :is-loading="isUpdating"
              data-test="confirm password update"
              native-type="submit"
              text="Update password"
              type="primary"
              @click="updatePassword"
            />
          </template>
        </AppResetPasswordForm>
      </ElDialog>
    </template>
    <div id="app-content">
      <RouterView v-if="showRoute" />
    </div>
  </div>
</template>

<script>
  import { setUser as SentrySetUser } from '@sentry/vue';
  import LogRocket from 'logrocket';
  import { mapState, mapWritableState, mapStores } from 'pinia';
  import { useLaunchDarklyStore } from '@/stores/launchDarkly.js';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useAccountStore } from '@/stores/account.js';
  // components
  import GlobalNavigation from '@/components/GlobalNavigation/index.vue';
  // services
  import ServiceAccount from '@/services/account.js';
  // mixins
  import project from '@/mixins/project.js';
  // utils
  import { identifyLDUser } from '@/utils/launchDarkly.js';
  import {
    identifySegmentUser,
    trackSegmentPage,
  } from '@watchtowerbenefits/es-utils-public';
  import { canUseThirdParty } from './utils/general.js';
  import { config } from './utils/config.js';

  // When we are in production or using segment in third_parties (npm run dev:with --third_parties=segment)
  const useAnalytics = config.analyticsEnabled(['production', 'demo', 'qa']);

  /**
   * Main App File
   *
   * @exports App
   */
  export default {
    name: 'App',
    components: { GlobalNavigation },
    mixins: [project],
    data: () => ({
      isUpdating: false,
      password: '',
      isValidPassword: false,
      directTraffic: true,
    }),
    computed: {
      ...mapWritableState(useAccountStore, ['userInfo', 'accountIsLoaded', 'auth']),
      ...mapState(useLaunchDarklyStore, {
        launchDarklyConfig: 'config',
      }),
      ...mapState(useProjectStore, ['project', 'employerName', 'proposalDocumentId']),
      ...mapStores(useCarrierInfoStore),
      /**
       * Evaluates the route meta pageType to see if it's a non-authentication page and the account info is loaded.
       *
       * @returns {boolean}
       */
      showRoute() {
        return !this.isAuthenticatedPage || this.accountIsLoaded;
      },
      /**
       * Evaluates the route meta pageType to see if it's an authentication or quote submission page.
       *
       * @returns {boolean}
       */
      isAuthenticatedPage() {
        return !['authentication', 'quoteSubmission'].includes(this.$route.meta.pageType);
      },
      /**
       * Returns the class for the page type
       *
       * @returns {string}
       */
      pageTypeClass() {
        return this.isAuthenticatedPage ? 'authenticated-page' : 'unauthenticated-page';
      },
    },
    watch: {
      $route: {
        /**
         * checks a few things if route changes
         *
         * @param {object} to - the route being navigated to
         */
        handler(to) {
          // dynamic page titles to make things make more sense on Intercom history
          if (to.meta.aliasTitles) {
            const tabName = to.path.split('/').pop();
            const titlePrefix = to.meta.aliasTitles[tabName] || to.meta.aliasTitles.root;

            document.title = `${titlePrefix} | ThreeFlow`;
          } else {
            document.title = to.meta.title
              ? `${to.meta.title} | ThreeFlow`
              : 'ThreeFlow';
          }

          // when the route changes we need to tell segment and inline manual the page has changed
          if (useAnalytics) {
            const pagePayload = {
              name: this.$route.name,
              path: this.$route.path,
              url: window.location.href,
              project_id: this.projectId,
              project_name: this.employerName ?? null,
              proposal_id: this.proposalDocumentId,
              direct_traffic: this.directTraffic,
            };

            trackSegmentPage(this.$route.name, pagePayload);
          }

          this.directTraffic = false;
        },
        immediate: true,
      },
      /**
       * runs getAllProjectData with new projectId if projectId changes
       *
       * @param {string} newValue
       */
      '$route.params.projectId': async function (newValue) {
        if (newValue && !['SignOut', 'ProjectSignOut'].includes(this.$route.name)) {
          await this.getAllProjectData(newValue);
        }
      },
      /**
       * This watcher will trigger an "identify" call to several services which can then attribute actions/events/views to a user.
       * userInfo.id is set to null on sign out, so we check that it exists first because we only want to make these identity calls
       * for a signed in user.
       *
       * @param {number} newId
       */
      'userInfo.id': function (newId) {
        if (newId) {
          if (useAnalytics || canUseThirdParty('logrocket')) {
            const {
              id,
              email,
              roles,
              first_name: firstName,
              last_name: lastName,
            } = this.userInfo;

            LogRocket.identify(id.toString(), {
              email,
              name: `${firstName} ${lastName}`,
              roles: roles.map(({ name }) => name).join(', '),
            });
          }

          const {
            id: carrierId,
            name: carrierName,
            logoUrl,
          } = this.carrierInfoStore;

          if (useAnalytics || canUseThirdParty('sentry')) {
            SentrySetUser({
              ...this.userInfo,
              carrier: {
                id: carrierId,
                name: carrierName,
                logoUrl,
              },
              projectId: this.projectId,
            });
          }

          if (useAnalytics || canUseThirdParty('segment')) {
            identifySegmentUser({
              ...this.userInfo,
              carrier_name: carrierName,
              carrier_id: carrierId ?? null,
            });
          }
        }
      },
      /**
       * update launch darkly identity whenever user key is changed and has a value
       *
       * @param {string} newKey
       */
      'launchDarklyConfig.user.key': function (newKey) {
        if (!newKey) return;

        // Always identify the user to LD.
        identifyLDUser(this.launchDarklyConfig);
      },
    },
    /**
     * set all user specific classes
     */
    mounted() {
      const uaString = navigator.userAgent;
      const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);

      if (match) {
        const ieVersion = parseInt(match[2], 10);

        if (ieVersion < 12) {
          document.querySelector('body').classList.add('is-ie');
        } else {
          document.querySelector('body').classList.add('is-ie-edge');
        }
      }

      if (
        this.$route.params.projectId
        && this.auth.status
        && !this.projectIsLoaded
        && !this.projectIsLoading
      ) {
        this.getAllProjectData(this.$route.params.projectId);
      }
    },
    methods: {
      /**
       * updates user password
       */
      async updatePassword() {
        this.isUpdating = true;
        try {
          await ServiceAccount.changePassword({ password: this.password });
          this.$message({
            showClose: true,
            message: 'Success! Your password has been updated.',
            type: 'success',
          });
          this.userInfo.temp_password = false;
        } catch {
          this.$message({
            duration: 10000,
            message: 'There was an error updating your password. Please contact support.',
            showClose: true,
            type: 'error',
          });
        } finally {
          this.isUpdating = false;
          this.password = '';
        }
      },
    },

  };
</script>

<style lang="scss" scoped>
  header {
    border-bottom: 1px solid var(--tf-gray-medium);
  }

  :deep(.reset-password-dialog) {
    .el-form-item__error {
      position: relative;
    }
  }
</style>
