<template>
  <section
    v-if="!isInactive"
    class="auth-form"
  >
    <AuthPageHeader title="You have been successfully signed out." />
    <p>You'll be redirected to the sign-in page shortly.</p>
  </section>
</template>

<script>
  // pinia
  import { mapActions } from 'pinia';
  import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
  import { useAccountStore } from '@/stores/account.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { useProductTableStore } from '@/stores/productTable.js';
  // services
  import ServiceAccount from '@/services/account.js';
  // ui
  import { deleteCookie } from '@watchtowerbenefits/es-utils-public';
  import { config } from '@/utils/config.js';

  const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;

  /**
   * SignOut Page
   *
   * @exports Authentication/SignOut
   */
  export default {
    name: 'UserSignOut',
    computed: {
      /**
       * Check to see if the $route query has a redirect property.
       *
       * @returns {boolean}
       */
      isInactive() {
        const routeQuery = this.$route.query;

        return Object.prototype.hasOwnProperty.call(routeQuery, 'redirect');
      },
    },
    /**
     * redirect user to sign-in if inactive
     */
    mounted() {
      const { query, params: { projectId } } = this.$route;
      let route = { name: 'SignIn' };

      if (projectId) {
        route = {
          name: 'ProjectSignIn',
          params: { projectId },
        };
      }

      this.clearData();

      if (this.isInactive) {
        this.setUserInactive(this.isInactive);
        this.$router.push({ ...route, query });

        return;
      }

      ServiceAccount
        .signOut()
        .then(() => {
          setTimeout(() => {
            this.$router.push({ ...route });
          }, 5000);
        })
        .catch(() => {});
    },
    methods: {
      ...mapActions(useQuoteEditsStore, ['resetQuoteEditState']),
      ...mapActions(useAccountStore, ['signOutUser', 'setUserEmail', 'setAuthStatus', 'setUserInactive']),
      ...mapActions(useProjectStore, ['resetProjectState']),
      ...mapActions(useProductStore, ['resetProductState']),
      ...mapActions(useProductTableStore, ['resetProductTableState']),
      /**
       * Trigger a bunch of actions and store updates to reset data/cookies.
       */
      clearData() {
        // 'resetProjectState' needs to be first so all project level views won't load.
        this.resetProjectState();
        this.resetProductState();
        this.resetProductTableState();
        this.setAuthStatus();
        this.resetQuoteEditState();
        this.setUserEmail();
        this.signOutUser();

        window.localStorage.setItem('auth', false);
        window.localStorage.removeItem('confirmed');

        deleteCookie(`${cookieNamespace}-auth-token`);
        deleteCookie(`${cookieNamespace}-user-roles`);
      },
    },
  };
</script>
