<template>
  <div class="rfp-info-tab">
    <div class="rfp-content">
      <div class="rfp-column">
        <h3>RFP information</h3>
        <RfpTable
          v-if="project && project.rfp_info"
          :hide-params="['market_activity']"
          :info="project.rfp_info"
          :project-type="project.type"
          :has-stop-loss="hasStopLoss"
        />
        <div class="rfp-info-copy-link-container">
          <CopyLinkContainer
            v-if="project"
            :text="`${project.employer.name} share link:`"
            :link="`${carrierPortalDomain}/project/${projectId}`"
            data-test="share link container"
          />
        </div>
      </div>
      <RfpDocuments data-test="rfp documents column">
        <h3
          slot="title"
          v-text="'RFP documents'"
        />
      </RfpDocuments>
    </div>
  </div>
</template>

<script>
  // pinia
  import { mapState } from 'pinia';
  // Components
  import RfpDocuments from '@/components/RfpDocuments/index.vue';
  import { useProjectStore } from '@/stores/project.js';
  import { isStopLossProduct } from '@watchtowerbenefits/es-utils-public';
  import { config } from '@/utils/config.js';

  /**
   * Project Overview RFP Info Tab
   *
   * @exports RfpOverview/RfpInfo
   */
  export default {
    name: 'RfpInfo',
    components: { RfpDocuments },

    data: () => ({
      carrierPortalDomain: config.VUE_APP_CARRIER_PORTAL_DOMAIN.replace('https://', ''),
    }),

    computed: {
      ...mapState(useProjectStore, ['project', 'projectId', 'proposalDocument']),
      /**
       * Tests to see if there are any stop loss products.
       *
       * @returns {boolean}
       */
      hasStopLoss() {
        return this.project.project_products
          && this.project.project_products.some(
            (product) => isStopLossProduct(product),
          );
      },
    },
  };
</script>

<style lang="scss" scoped>
  h3 {
    margin-bottom: 23px;
  }

  .rfp-column {
    margin: 0 20px 23px;

    @media (min-width: 1024px) {
      width: calc(50% - 40px);
    }
  }

  .rfp-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: var(--carrier-max-width);
    margin: 0 auto;

    @media (min-width: 1024px) {
      flex-flow: row wrap;
    }
  }

  :deep(.rfp-table) {
    td {
      font-size: 14px;
    }
  }
</style>
