<template>
  <section
    id="page-content"
    v-loading="!currentProductIsLoaded"
  >
    <section
      v-if="currentProductIsLoaded"
      id="data-entry-container"
    >
      <RfpStatus
        data-test="rfp status"
      />
      <div class="rfp-overview-tabs-container">
        <TfTabs
          value="YourQuotes"
          @tab-click="onTabClicked"
        >
          <ElTabPane
            v-for="{ name, title, badgeCount } in rfpOverviewTabs"
            :key="name"
            :class="{
              'full-width-tab-pane': ['YourQuotes', 'RfpInfo'].includes(name),
              'is-updates-tab': name === 'UpdatesEdits'
            }"
            :lazy="true"
            :name="name"
          >
            <template slot="label">
              {{ title }}
              <sup v-if="badgeCount">
                <TfBadge
                  :value="badgeCount"
                  status="error"
                  is-counter
                />
              </sup>
            </template>
            <RouterView v-if="name === $route.name" />
          </ElTabPane>
        </TfTabs>
      </div>
      <div class="sub-tabs">
        <TfTabs
          :value="subtab"
          @tab-click="handleTabClick"
        >
          <template v-for="step in steps">
            <ElTabPane
              v-if="!step.hideIfTab"
              :key="`step-${step.text}`"
              :label="step.text"
              :name="step?.to?.name"
            />
          </template>
        </TfTabs>
      </div>
      <div id="data-entry-content">
        <!-- The quote edits panel -->
        <TfPanel
          v-if="quoteEditsPanel.visible"
          :open="quoteEditsPanel.open"
          :back-url="quoteEditsBackUrl"
          panel-trigger="panels"
        >
          <QuoteEditsContainer heading="Quote Edits" />
        </TfPanel>
        <!-- Comments Panel -->
        <TfPanel
          v-if="commentsPanel.visible"
          :open="commentsPanel.open"
          :back-url="commentsPanelBackUrl"
          panel-trigger="panels"
        >
          <CommentContainer />
        </TfPanel>
        <!-- Broker Updates Panel -->
        <TfPanel
          v-if="brokerUpdatesPanel.visible"
          :open="brokerUpdatesPanel.open"
          :back-url="brokerUpdatesPanelBackUrl"
          panel-trigger="panels"
        >
          <BrokerUpdatesContainer />
        </TfPanel>

        <RouterView v-if="projectIsLoaded && currentProduct" />
      </div>
    </section>
  </section>
</template>

<script>
  // pinia
  import { mapActions, mapState, mapWritableState } from 'pinia';
  import { useBrokerUpdatesStore } from '@/stores/brokerUpdates.js';
  import { useCommentsStore } from '@/stores/comments.js';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  // Mixins
  import project from '@/mixins/project.js';
  import RfpOverview from '@/mixins/rfpOverview.js';
  // Components
  import QuoteEditsContainer from '@/components/QuoteEdits/QuoteEditsContainer.vue';
  import BrokerUpdatesContainer from '@/components/BrokerUpdates/BrokerUpdatesContainer.vue';
  import CommentContainer from '@/components/Comments/index.vue';
  import RfpStatus from '@/components/RfpOverview/RfpStatus.vue';
  /**
   * Product Landing
   *
   * @exports Product/ProductLanding
   */
  export default {
    name: 'ProductLanding',
    components: {
      QuoteEditsContainer,
      BrokerUpdatesContainer,
      CommentContainer,
      RfpStatus,
    },
    mixins: [project, RfpOverview],
    provide: {
      isPlanSummary: false,
    },
    props: {
      /**
       * The id of the product
       *
       * @type {number}
       */
      productId: {
        type: [String, Number],
        required: true,
      },
    },
    data: () => ({
      currentOpenPanel: '',
      canOutsideClick: true,
    }),
    computed: {
      ...mapState(usePlanDesignStore, ['validPlanDesign']),
      ...mapState(useQuoteEditsStore, ['quoteEditsPanel']),
      ...mapState(useBrokerUpdatesStore, ['notifications']),
      ...mapState(useBrokerUpdatesStore, { brokerUpdatesPanel: 'brokerPanel' }),
      ...mapState(useCommentsStore, ['unreadComments', 'commentsPanel']),
      ...mapState(useNotificationsStore, ['unreadEdits']),
      ...mapState(useProjectStore, [
        'project',
        'employerName',
        'proposals',
      ]),
      ...mapWritableState(useProductStore, [
        'currentProductIsLoaded',
        'currentProduct',
        'normalizedValues',
        'isStopLoss',
      ]),
      ...mapState(useRateEntryStore, ['validRateEntry']),
      subtab: {
        get() {
          return this.$route.name;
        },
      },
      /**
       * the tabs for the side bar
       *
       * @returns {Array}
       */
      rfpTabs() {
        const tabs = [
          {
            id: 'YourQuotes',
            label: 'Quotes',
          },
          {
            id: 'ProductSummary',
            label: 'Product summary',
          },
          {
            id: 'RfpInfo',
            label: 'Overview',
          },
          {
            id: 'BrokerUpdates',
            label: 'Broker updates',
            badgeCount: this.notifications,
          },
          {
            id: 'QuoteEdits',
            label: 'Quote edits',
            badgeCount: this.unreadEdits,
          },
          {
            id: 'MarketActivity',
            label: 'Market activity',
          },
          {
            id: 'Comments',
            label: 'Comments',
            badgeCount: this.unreadComments,
          },
          {
            id: 'DocViewer',
            label: 'Document viewer',
          },
        ];

        // hideMarketTab is from the RfpOverview mixin
        return this.hideMarketTab
          ? tabs.filter(({ id }) => id !== 'MarketActivity')
          : tabs;
      },
      /**
       * The url to redirect someone to they close the quote edits panel
       *
       * @returns {string}
       */
      quoteEditsBackUrl() {
        const backUrlArray = this.$route.fullPath.split('/quote-edits');

        if (backUrlArray) {
          return backUrlArray[0];
        }

        return '';
      },

      /**
       * The url to redirect someone to when they close the comments panel
       *
       * @returns {string}
       */
      brokerUpdatesPanelBackUrl() {
        const backUrlArray = this.$route.fullPath.split('/broker-updates');

        if (backUrlArray) {
          return backUrlArray[0];
        }

        return '';
      },

      /**
       * Object used to create the progress steps for the Plan Design button.
       *
       * @returns {object}
       */
      btnPlanDesign() {
        const active = /^(PlanDesign|PlanDesignReview)$/i.test(this.$route.name);
        const obj = {
          text: 'Plan design',
          to: {
            name: this.$route.meta.readonly
              ? 'PlanDesignReview'
              : 'PlanDesign',
          },
          state: 'started',
          active,
        };

        if (this.validPlanDesign && !active) {
          obj.btnIcon = 'fa-solid fa-check';
          obj.state = 'completed';
        } else {
          obj.btnText = '1';
        }

        return obj;
      },
      /**
       * Object used to create the progress steps for the Rate Entry button.
       *
       * @returns {object}
       */
      btnRateEntry() {
        const active = /^(RateEntry|RateEntryReview)$/i.test(this.$route.name);
        const obj = {
          text: 'Rate entry',
          to: {
            name: this.$route.meta.readonly
              ? 'RateEntryReview'
              : 'RateEntry',
          },
          state: this.validRateEntry ? 'completed' : 'not-started',
          active,
        };

        if (this.validRates && !active) {
          obj.btnIcon = 'fa-solid fa-check';
          obj.state = 'completed';
        } else {
          obj.btnText = '2';
        }

        return obj;
      },
      /**
       * Object used to create the progress steps for the Firm button.
       *
       * @returns {object}
       */
      btnFirm() {
        const isDisabled = ['firm_quote_requested', 'no_action'].includes(this.currentProduct.stop_loss_state) || !this.isCompleted;

        return {
          btnIcon: isDisabled ? null : 'fa-solid fa-check',
          btnText: isDisabled ? '4' : null,
          state: isDisabled ? 'not-started' : 'completed',
          text: 'Firm',
        };
      },

      /**
       * The url to redirect someone to when they close the comments panel
       *
       * @returns {string}
       */
      commentsPanelBackUrl() {
        const backUrlArray = this.$route.fullPath.split('/comments');

        if (backUrlArray) {
          return backUrlArray[0];
        }

        return '';
      },

      /**
       * Check if plan design is being edited
       * This means the user is on the plan design page, it's not the first time through, and they are not "reviewing" the rates
       *
       * @returns {boolean}
       */
      editingPlanDesign() {
        return this.currentProduct.state === 'editing' && this.$route.name === 'PlanDesign';
      },

      /**
       * Check if rates are being edited
       * This means the user is on the rate page, it's not the first time through, and they are not "reviewing" the rates
       *
       * @returns {boolean}
       */
      editingRates() {
        return this.currentProduct.state === 'editing' && this.$route.name === 'RateEntry';
      },

      /**
       * Check if this is a completed product.
       *
       * @returns {boolean}
       */
      isCompleted() {
        return this.currentProduct.state === 'completed';
      },

      /**
       * Check if rate_guarantee exists and that the rates structure is valid.
       *
       * @returns {boolean}
       */
      validRates() {
        return this.isStopLoss
          ? this.validRateEntry
          : !!(this.validRateEntry && this.currentProduct.rate_guarantee);
      },
      /**
       * Steps used to render tabs.
       *
       * @returns {Array}
       */
      steps() {
        const steps = [
          this.btnRateEntry,
          this.btnPlanDesign,
        ];

        return steps;
      },
    },

    watch: {
      // Aaron TO DO: Should we move this over to be controlled by the route instead of being a watcher?
      // Initial dispatch for productId is in `mount``
      /**
       * gets the current product and clears its rates and plan designs based
       * on product id when productId changes in params
       */
      async productId() {
        if (!this.productId) {
          return;
        }
        try {
          await this.loadData();
        } catch {
          this.$message({
            type: 'error',
            message: 'There was an error loading the product.',
          });
        }
        await this.loadProductNormalizedValues(this.productId);
      },
    },

    /**
     * Check to see if the user was invited before anything.
     */
    created() {
      this.checkInvited();
      this.setProductsFiltersSelected([+this.productId]);
    },

    /**
     * get the current product then get project data
     */
    async mounted() {
      try {
        await this.loadData();
      } catch {
        // Redirect the user back to YourQuotes if their renewal documents are still processing or they are unauthorized to access the product at this time.
        this.$message({
          type: 'error',
          message: 'There was an error loading the product.',
        });

        this.$router.push({ name: 'YourQuotes' });

        return;
      }

      await this.loadProductNormalizedValues(this.productId);

      // The project data might already be loading from a parent component so escape early.
      if (!this.projectIsLoading && !this.projectIsLoaded) {
        this.getAllProjectData(this.$route.params.projectId);
      }
    },

    /**
     * Destroy the current product in the store before destroying this component
     */
    beforeDestroy() {
      this.currentProduct = null;
      this.normalizedValues = [];
      this.currentProductIsLoaded = false;
      this.clearPlanDesign();
      this.clearRateEntry();
    },

    methods: {
      ...mapActions(usePlanDesignStore, [
        'clearPlanDesign',
        'getPlanDesign',
      ]),
      ...mapActions(useQuoteEditsStore, ['setProductsFiltersSelected']),
      ...mapActions(useProductStore, [
        'getCurrentProduct',
        'loadNormalizedValues',
        'validateProduct',
      ]),
      ...mapActions(useRateEntryStore, [
        'getRateEntry',
        'clearRateEntry',
      ]),
      /**
       * Load the product, rate entry, and plan design from the API
       */
      async loadData() {
        await this.getCurrentProduct(this.productId);

        // Get Rate Entry
        this.clearRateEntry();
        await this.getRateEntry({ productId: this.productId });

        // Get Plan Design
        this.clearPlanDesign();
        await this.getPlanDesign(this.productId);

        try {
          await this.validateProduct(this.productId);
        } catch {
          // rates, rate guarantee, or plan design is invalid
        }
      },
      /**
       * Handle Tab Clicks
       *
       * @param {object} payload
       * @param {object} payload.$options
       * @param {object} payload.$options.propsData
       * @param {string} payload.$options.propsData.label
       */
      handleTabClick({ $options: { propsData: { label } } }) {
        const { to } = this.steps.find((step) => step.text === label);

        this.$router.push(to);
      },
      /**
       * Get the normalized values for the product.
       *
       * @param {number} productId
       */
      async loadProductNormalizedValues(productId) {
        try {
          await this.loadNormalizedValues(productId);
        } catch {
          this.$message({
            type: 'error',
            message: 'There was an error loading the drop down values, typing in values is still available. Refresh to access the drop down values.',
          });
        }
      },
      /**
       * Handle action when a tab is clicked. If the tab is a panel, open the panel instead of
       * navigating to the tab's route.
       *
       * @param {string} tab
       */
      onTabClicked(tab) {
        // Check for tab as object with name prop, if not tab is string.
        const tabCheck = tab?.name ?? tab;
        const topTabs = ['QuoteEdits', 'BrokerUpdates', 'DocViewer'];

        if (topTabs.includes(tabCheck)) {
          this.currentOpenPanel = tabCheck;
          this.canOutsideClick = false;
        } else {
          this.$router.push({ name: tabCheck });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #page-content,
  #data-entry-container,
  #data-entry-content,
  .data-entry-landing {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }

  #data-entry-content {
    position: relative;
  }

  h3 {
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 600;
  }

  /* Data Entry Footer styles */
  :deep() footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background: var(--tf-gray-light);
    border-top: 1px solid var(--tf-gray-light-medium);

    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
  }

  :deep(.data-entry-containers) {
    flex: 2;
    overflow-y: auto;
  }

  :deep() section.active {
    &:not(:last-child) {
      border-bottom: 1px solid var(--tf-gray-light-medium);
    }
  }

  /* Side Panels */
  // 412px is the height of the available space for the panel (after subtracting the height of the header, tabs, etc)
  #data-entry-content :deep(.panel) {
    height: calc(100% - 412px);
    bottom: 93px; // Height of the footer
  }

  .panel {
    display: flex;
  }

  :deep() .el-tabs {
    &,
    &__content {
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }

    &__nav-wrap {
      padding-left: 32px;
    }
  }

  .sub-tabs {
    margin: 20px 40px 0;
  }
</style>
