import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * All quote edits/history related ajax calls
 *
 * @exports Services/QuoteEdits
 */
export default {
  /**
   * Get proposal level quote history
   *
   * @param {number} documentId
   * @returns {Promise}
   */
  getQuoteHistory(documentId) {
    const url = `${normalizedEndpoint}/edit_history/document/${documentId}`;

    return axios.get(
      url,
      axiosConfig(),
    )
      .then(({ data }) => data);
  },
};
