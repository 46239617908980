<template>
  <ElDialog
    id="quote-file-modal"
    :visible="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="Quote file"
    top="70px"
    @close="$emit('update:visible', false)"
    @closed="resetModal"
  >
    <div v-loading="csvQuoteStore.isLoading">
      <!-- alert messages -->
      <AppAlert
        v-if="csvQuoteStore.alert.type"
        :type="csvQuoteStore.alert.type"
        class="upload-error-disclaimer"
        data-test="csv quote error"
        show-icon
      >
        <!-- per zeplin, we show a bulleted list if more than 1 message -->
        <!-- eslint-disable vue/no-v-html -->
        <ul v-if="csvQuoteStore.alert.messages.length > 1">
          <li
            v-for="(message, index) of csvQuoteStore.alert.messages"
            :key="`alert-message-${index}`"
            v-html="message"
          />
        </ul>
        <p
          v-else
          v-html="csvQuoteStore.alert.messages[0]"
        />
      </AppAlert>
      <!-- eslint-enable vue/no-v-html -->

      <!-- upload or submit component -->
      <component
        :is="currentStep"
        :parent-step.sync="currentStep"
        :data-test="`${currentStep} step`"
      />

      <!-- file-list section -->
      <div class="processed-document">
        <h4>Document processed</h4>
        <p
          v-if="!csvQuoteStore.file.name"
          data-test="no csv uploaded disclaimer"
        >
          No documents uploaded.
        </p>
        <div
          v-else
          class="processed-document-file-list"
        >
          <span
            class="processed-document-filename"
            data-test="processed filename"
          >
            {{ csvQuoteStore.file.name }}
          </span>
          <template v-if="!csvQuoteStore.id">
            <ElProgress
              v-bind="{
                showText: false,
                strokeWidth: 18,
                percentage: csvQuoteStore.uploadProgress
              }"
              data-test="csv upload progress"
            />
            <AppButton
              data-test="cancel csv upload"
              icon="fa-solid fa-times"
              size="text-small"
              text="Cancel upload"
              type="danger"
              @click="resetModal"
            />
          </template>
          <AppButton
            v-else
            data-test="delete csv upload"
            icon="fa-solid fa-trash-can"
            size="text-small"
            text="Delete file"
            type="danger"
            @click="resetModal"
          />
        </div>
      </div>
    </div>

    <!-- bottom button section -->
    <template #footer>
      <div
        class="footer"
        :class="{ submit: currentStep === 'submit' }"
      >
        <p
          v-if="currentStep === 'submit'"
          id="terms-disclaimer"
        >
          By submitting, you agree to our&nbsp;
          <AppButton
            type="primary"
            size="text"
            @click="showTermsDialog = true"
          >
            terms
          </AppButton>
          .
        </p>
        <AppButton
          data-test="cancel csv quote"
          size="text"
          text="Cancel"
          @click="$emit('update:visible', false)"
        />
        <AppButton
          v-if="currentStep === 'upload'"
          :is-disabled="!csvQuoteStore.isCsvValidated"
          data-test="upload csv quote"
          text="Continue"
          @click="currentStep = 'submit'"
        />
        <AppButton
          v-if="currentStep === 'submit'"
          :is-disabled="!isQuoteValuesValid"
          :is-loading="csvQuoteStore.isLoading"
          data-test="submit csv quote"
          icon="fa-solid fa-paper-plane"
          type="success"
          text="Submit quote"
          @click="submitQuote"
        />
      </div>
    </template>
    <!-- terms of use -->
    <ElDialog
      v-if="showTermsDialog"
      :visible.sync="showTermsDialog"
      title="Terms"
      top="70px"
      append-to-body
    >
      <AppTermsOfUse />
    </ElDialog>
  </ElDialog>
</template>

<script>
  import { mapActions, mapStores, mapState } from 'pinia';
  import { useCsvQuoteStore } from '@/stores/csvQuote.js';
  import { useProjectStore } from '@/stores/project.js';
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import Upload from '@/components/Modals/QuoteFileModal/QuoteFileUpload.vue';
  import Submit from '@/components/Modals/QuoteFileModal/QuoteFileSubmit.vue';

  export default {
    name: 'QuoteFileModal',
    components: {
      Upload,
      Submit,
    },
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
    },
    data: () => ({
      currentStep: 'upload',
      showTermsDialog: false,
    }),
    computed: {
      ...mapState(useProjectStore, ['proposalDocumentId']),
      ...mapState(useCsvQuoteStore, ['isQuoteValuesValid']),
      ...mapStores(useCsvQuoteStore),
    },
    methods: {
      ...mapActions(useCsvQuoteStore, ['submitCsvQuote']),
      /**
       * Clears all the quote props in the store and sets
       * the modal back to 'upload' (initial step)
       */
      resetModal() {
        this.csvQuoteStore.$reset();
        this.currentStep = 'upload';
      },
      /**
       * Submits the csv quote, updates the respective products in the store, and closes the modal.
       */
      async submitQuote() {
        try {
          await this.submitCsvQuote(this.csvQuoteStore.id, this.proposalDocumentId);

          trackSegmentEvent('Submit quote', segmentData());
          this.$emit('update:visible', false);
        } catch {
          this.$message({
            type: 'error',
            message: 'There was an error submitting your quote. Please try again.',
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep() {
    .el-dialog {
      max-width: 771px;
      width: 90%;

      &__body {
        padding: 30px;
        color: var(--tf-gray-dark);
      }

      &__footer {
        padding: 0;
      }
    }
  }

  .upload-error-disclaimer {
    margin-bottom: 25px !important; // stylelint-disable-line

    ul {
      margin-left: 15px;
    }

    li {
      list-style-type: disc;
    }

    p {
      margin-bottom: 0;
    }
  }

  .processed-document {
    border-top: 1px solid var(--tf-gray-light-medium);
    border-bottom: 1px solid var(--tf-gray-light-medium);
    margin-top: 36px;
    padding: 16px 0;

    &-file-list {
      display: flex;
      align-items: center;
    }

    &-filename {
      margin-right: 10px;
      display: flex;
      flex: 1;
      color: var(--tf-gray-dark);
    }
  }

  .el-progress {
    width: 137px;
    margin-right: 24px;
  }

  .footer {
    padding: 21px;
    margin-top: 30px;

    &.submit {
      background: var(--tf-gray-light);
      border-top: 1px solid var(--tf-gray-light-medium);
    }
  }

  #terms-disclaimer {
    // display flex + baseline allows the terms button to sit
    // at the proper basline level with the rest of the text
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-bottom: 20px;
  }
</style>
