<template>
  <div>
    <h4>Upload document</h4>
    <p>Benefits, rates, and provisions are populated directly from the uploaded file.</p>
    <p>
      Note: At this time only stop loss products can be submitted using a .CSV file upload.
      Any other coverages must be submitted using manual rate entry.
    </p>

    <!-- upload drag/drop window -->
    <ElUpload
      v-if="!csvQuoteStore.isCsvValidated"
      ref="upload"
      v-bind="{
        action: `${apiUrl}/v1/carrier_portal/csv_bid_submissions`,
        headers,
        data: { document_id: proposalDocumentId },
        beforeUpload: validateFile,
        onProgress: updateProgress,
        onSuccess: validateCSV,
        onError: checkError,
        disabled: Boolean(csvQuoteStore.file.name && !csvQuoteStore.id),
        class: {
          disabled: Boolean(csvQuoteStore.file.name && !csvQuoteStore.id)
        },
        showFileList: false
      }"
      drag
      name="csv_submission"
      accept=".csv"
    >
      <AppIcon icon="fa-solid fa-file-upload" />
      <div class="el-upload__text">
        Drop file here<br>
        or <em>click to upload</em>
      </div>
      <div
        slot="tip"
        class="el-upload__tip"
      >
        CSV file type only.
      </div>
    </ElUpload>

    <div
      v-else
      class="file-uploaded"
    >
      <AppIcon icon="fa-solid fa-arrow-right" /> Your quote file has been processed.
      Please <strong>continue</strong> to validation.
    </div>
  </div>
</template>

<script>
  // pinia
  import {
    mapActions, mapStores, mapWritableState, mapState,
  } from 'pinia';
  import { getCookie } from '@watchtowerbenefits/es-utils-public';
  import { useCsvQuoteStore } from '@/stores/csvQuote.js';
  import { useProjectStore } from '@/stores/project.js';
  // services
  import Interceptor from '@/services/interceptor.js';
  import ProposalService from '@/services/proposal.js';
  // icons
  import uploadFilesIcon from '@/assets/upload-files-icon.svg';
  // utils
  import { isValidFileSize, isValidExtension, flattenObjectValues } from '@/utils/file.js';
  import { config } from '@/utils/config.js';

  const apiUrl = config.VUE_APP_API_URL;
  const authKey = 'X-API-AUTHTOKEN';
  const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;

  /**
   * Upload sub-component for ModalQuoteFile component
   * Handles all the csv file uploading functionality for csv quote submission flow
   *
   * @exports Modals/QuoteFileModal/QuoteFileUpload
   */
  export default {
    name: 'QuoteFileUpload',
    props: {
      parentStep: {
        required: true,
        validator: (propValue) => ['upload', 'submit'].includes(propValue),
      },
    },
    data: () => ({
      apiUrl,
      headers: {
        'X-API-AUTHTOKEN': getCookie(`${cookieNamespace}-auth-token`),
      },
      uploadFilesIcon,
    }),
    computed: {
      ...mapWritableState(useCsvQuoteStore, ['alert']),
      ...mapStores(useCsvQuoteStore),
      ...mapState(useProjectStore, ['proposalDocumentId']),
    },
    /**
     * add a custom event for when a user drags files into the upload section
     */
    mounted() {
      if (this.checkUploadDragger()) {
        this.$refs.upload.$el
          .querySelector('.el-upload-dragger')
          .addEventListener('drop', this.dragDropFile);
      }
    },
    methods: {
      ...mapActions(useCsvQuoteStore, ['setQuote', 'resetAlert']),
      /**
       * if we get an unauthorized, it likely means someone signed in under the
       * same email while you were on this modal. in this case, sign the user out accordingly
       *
       * @param {object} error
       * @param {number} error.status
       */
      checkError({ status }) {
        this.csvQuoteStore.$reset();
        if ([401, 403].includes(status)) {
          Interceptor.inactiveLogout();
        }
      },
      /**
       * Checks for .el-upload-dragger
       *
       * @returns {boolean}
       */
      checkUploadDragger() {
        return this.$refs.upload && this.$refs.upload.$el.querySelector('.el-upload-dragger');
      },
      /**
       * When a file is dropped, component automatically filters out unacceptable file names
       * So we watch the drop as well to throw proper errors when necassary
       *
       * @param {object} event
       */
      dragDropFile(event) {
        const { files } = event.dataTransfer;

        // we only want to allow single file uploads. Let user
        // know this if they attempt to drag in multiple files
        if (files.length > 1) {
          Object
            .values(files)
            .forEach((file) => {
              const ref = this.$refs.upload;

              if (this.$refs.upload) {
                ref.abort(file);
              }
            });

          this.csvQuoteStore.$reset();
          this.setQuote({
            type: 'danger',
            messages: ['Please choose a single CSV file to upload.'],
          });

          return;
        }

        this.validateFile(files[0]);
      },
      /**
       * Used to update visual progress bar for file uploading
       *
       * @param {object} event
       * @param {number} event.percent
       */
      updateProgress({ percent }) {
        let progress = percent;

        // show the progress component as %3 less then it really
        // is (this way it doesn't look like it hangs at 100%)
        progress -= (progress * 0.03);
        this.setQuote({ uploadProgress: Math.floor(progress) });
      },
      /**
       * Before we send the file to the server we do some checks
       * on the auth-token, file extension/type, and file size
       *
       * @param {object} file
       * @returns {boolean}
       */
      validateFile(file) {
        this.resetAlert();
        const error = {
          isError: false,
          messages: [],
        };

        // sign out if the cookie is expired
        if (this.headers[authKey] !== getCookie(`${cookieNamespace}-auth-token`)) {
          this.csvQuoteStore.$reset();
          Interceptor.inactiveLogout();

          return false;
        }

        if (!isValidExtension({
          file,
          acceptedExtensions: ['csv'],
          acceptedFileTypes: ['text/csv'],
        })) {
          error.isError = true;
          error.messages.push('We do not accept this file type. Select a CSV file and try again.');
        }

        if (!isValidFileSize(file)) {
          error.isError = true;
          error.messages.push('File is too large. Uploads must be 20MB or less. Select a new file and try again');
        }

        // if invalid, show error message and don't allow upload
        if (error.isError) {
          this.alert = {
            type: 'danger',
            messages: error.messages,
          };

          return false;
        }

        // if valid, add file to store and continue
        this.setQuote({ file });

        return true;
      },

      /**
       * On a successful upload, update the quote props and then check if
       * CSV passes integration tests before moving to product validation
       *
       * @param {object} response
       * @param {object} response.csv_bid_submission
       * @param {number} response.csv_bid_submission.id
       */
      validateCSV({ csv_bid_submission: { id } }) {
        this.setQuote({
          id,
          uploadProgress: 100,
        });

        ProposalService
          .validateCsvFile(id, this.proposalDocumentId)
          .then(({
            csv_bid_submission: {
              bid_evaluation: {
                product_types: productTypes,
              },
            },
          }) => {
            this.setQuote({ isCsvValidated: true });

            // accumulate all proposals from the submission that will overwrite any previously submitted proposals
            const proposalsThatWillBeOverwritten = Object.values(productTypes)
              .reduce((overwriteAccumulator, { product_proposals: proposalArray }) => {
                proposalArray.forEach(({ existing_quote: existingQuote, label }) => {
                  if (existingQuote) {
                    overwriteAccumulator.push(label);
                  }
                });

                return overwriteAccumulator;
              }, []);

            if (proposalsThatWillBeOverwritten.length) {
              const labelString = proposalsThatWillBeOverwritten.join(', ');

              this.alert = {
                type: 'warning',
                messages: [
                  // eslint-disable-next-line max-len
                  `There is already a quote submitted for ${labelString} in this RFP. Submitting a new quote will replace the existing one.`,
                ],
              };
            }
          })
          .catch(({ response: { data: { code, message } } }) => {
            this.csvQuoteStore.$reset();

            // anything without a code is not coming from BE therefore
            // given a generic error message
            if (!code) {
              this.alert = {
                type: 'danger',
                messages: ['Something went wrong when attempting to upload your file. Please upload a CSV file to try again.'],
              };

              return;
            }
            const scopedMessage = message;

            // for sun life, there can be a product_identification property that is an object containing arrays
            // we have to flatten this too if it exists.
            if (scopedMessage.product_identification) {
              // eslint-disable-next-line max-len
              scopedMessage.product_identification = flattenObjectValues(scopedMessage.product_identification[0]);
            }

            // error.message is an object where each property is an array of strings
            // we convert the object to an array of arrays and then flatten
            this.alert = {
              type: 'danger',
              messages: flattenObjectValues(message),
            };
          })
          .finally(() => {
            // need to remove any listeners we've added manually before component is removed
            if (this.checkUploadDragger()) {
              this.$refs.upload.$el
                .querySelector('.el-upload-dragger')
                .removeEventListener('drop', this.dragDropFile);
            }
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep() {
    .el-upload {
      margin-top: 92px;
    }

    .el-upload,
    .el-upload-dragger {
      width: 100%;
      color: var(--tf-blue);

      svg {
        width: 56px;
        height: 61px;
      }
    }
  }

  .disabled :deep() {
    .el-upload-dragger {
      color: var(--tf-gray-medium);
      border-color: var(--tf-gray-medium);
      background-color: var(--tf-gray-light);
      cursor: default;
    }

    .el-upload__text,
    em {
      /* stylelint-disable-next-line declaration-no-important */
      color: var(--tf-gray-medium) !important;
    }
  }

  .file-uploaded {
    color: var(--tf-gray-dark);
    font-size: 20px;
    padding: 100px 0;
    text-align: center;

    svg {
      height: 17px;
      width: 18px;
    }
  }
</style>
