<template>
  <CpTableRow>
    <CpTableCell data-test="product type cell">
      {{ localProductType.name }}
    </CpTableCell>
    <CpTableCell data-test="certified cell">
      <ElSwitch
        v-model="localProductType.certified"
        aria-label="toggle certified"
        data-test="toggle certified"
        @change="$emit(
          'updateCertified',
          {
            productTypeId: localProductType.id,
            certified: localProductType.certified
          })"
      />
    </CpTableCell>
    <CpTableCell
      v-if="isEditingStates"
      class="states-editing"
      data-test="edit states cell"
    >
      <TfMultiSelect
        v-model="localProductTypeStates"
        :total-options-available="statesList.length"
        :options="statesList"
        empty-text="No states available"
        label="name"
        popper-class="states-multi-select-popover multi-select-popover"
        value-key="id"
        append-to-body
        data-test="edit states"
      />
      <div class="btn-group align-end">
        <AppButton
          data-test="cancel changes"
          icon="fa-solid fa-xmark"
          size="icon"
          text="cancel changes"
          type="danger"
          @click="cancelEditingStates()"
        />
        <AppButton
          :is-disabled="!localProductTypeStates.length"
          data-test="save changes"
          icon="fa-solid fa-check"
          size="icon"
          text="save changes"
          type="success"
          @click="updateStates()"
        />
      </div>
    </CpTableCell>
    <CpTableCell
      v-else
      class="states-read-only"
      data-test="states cell"
    >
      <div class="states">
        {{ localProductType.states.map(({ code }) => code).join(', ') }}
      </div>
      <AppButton
        :data-test="`edit ${productType.name.toLowerCase()} states`"
        icon="fa-solid fa-pencil"
        size="icon"
        text="edit states"
        @click="isEditingStates = true"
      />
    </CpTableCell>
    <CpTableCell>
      <div class="btn-group-column">
        <AppButton
          data-test="remove product type"
          icon="fa-solid fa-trash-can"
          size="text-small"
          text="Remove product type"
          type="danger"
          @click="$emit('delete', productType.id)"
        />
        <AppButton
          data-test="move product type"
          icon="fa-solid fa-exchange-alt"
          size="text-small"
          text="Move product type"
          @click="$emit('move', { id: productType.id, name: productType.name })"
        />
      </div>
    </CpTableCell>
  </CpTableRow>
</template>

<script>
// 3rd Party
  import { cloneDeep } from 'lodash';
  import { CpTableCell, CpTableRow } from '@watchtowerbenefits/cp-components';

  /**
   * Product Type line item
   *
   * @exports Management/NewProductType
   */
  export default {
    name: 'ProductType',
    components: {
      CpTableCell,
      CpTableRow,
    },
    props: {
      /**
       * The productType object, passed from a Carrier's offered product types.
       */
      productType: {
        type: Object,
        required: true,
      },
      /**
       * An array of all states with the corresponding IDs for the back-end.
       */
      statesList: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      localProductType: {},
      isEditingStates: false,
    }),
    computed: {
      /**
       * States is actually an array of objects in the response, but our dropdown expects an array.
       * This computed getter/setter pair picks up the slack by reducing/restoring the data the
       * dropdown doesn't like.
       */
      localProductTypeStates: {
        /**
         * Get a flat array of the state ids for our dropdown.
         *
         * @returns {Array}
         */
        get() {
          return this.localProductType.states.map(({ id }) => id);
        },
        /**
         * Update the local States value to reflect what's expected in the API response so we don't lose data.
         *
         * @param {Array} selectedStateIds
         */
        set(selectedStateIds) {
          this.$set(
            this.localProductType,
            'states',
            this.statesList.filter(({ id }) => selectedStateIds.includes(id)),
          );
        },
      },
    },
    /**
     * On created, we clone the productType so that any changes made are not applied to
     * the carrier productTypes until the checkmark is clicked.
     */
    created() {
      this.localProductType = cloneDeep(this.productType);
    },
    methods: {
      /**
       * Cancel editing states
       * Sets product.states back to what they were before edit was clicked
       * Otherwise, the selection remains if edit is clicked again
       */
      cancelEditingStates() {
        this.isEditingStates = false;
        this.$set(this.localProductType, 'states', cloneDeep(this.productType.states));
      },
      /**
       * Update product type states by way of an emit to the parent.
       */
      updateStates() {
        this.$emit('updateStates', {
          productTypeId: this.localProductType.id,
          states: this.localProductType.states,
        });
        this.isEditingStates = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .states-read-only {
    display: flex;
    align-self: flex-start;

    .states {
      margin-right: 10px;
    }
  }

  .states-editing {
    display: flex;
    justify-content: space-between;
    align-self: flex-start;

    > span {
      flex: 1;
      margin-right: 10px;
    }
  }
</style>
<style>
  .states-multi-select-popover {
    height: 200px;
    overflow: scroll;
  }
</style>
