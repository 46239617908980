<template>
  <div
    v-if="isLoaded"
    class="contact-tables-container"
  >
    <ContactTable
      title="Carrier team"
      :users="carrierUsers"
    />
    <ContactTable
      title="Broker team"
      :users="brokerUsers"
    />
  </div>
</template>

<script>
  import projectService from '@/services/project.js';
  import ContactTable from '@/components/Contacts/ContactTable.vue';

  export default {
    name: 'EventsContact',
    components: {
      ContactTable,
    },
    props: {
      projectId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        brokerUsers: {},
        carrierUsers: {},
        isLoaded: false,
      };
    },
    created() {
      this.loadContacts();
    },
    methods: {
      /**
       * Try to get the carrier contacts and format them if successful.
       */
      async loadContacts() {
        try {
          this.isLoaded = false;
          const {
            broker_users: brokerUsers,
            carrier_users: carrierUsers,
          } = await projectService.getCarrierContacts(this.projectId);

          this.carrierUsers = this.formatUsers(carrierUsers, 'carrier');
          this.brokerUsers = this.formatUsers(brokerUsers, 'broker');
        } catch (error) {
          this.$message({
            showClose: true,
            message: 'Could not get contacts',
            type: 'error',
          });
          this.$router.push({ name: 'YourQuotes' });
        } finally {
          this.isLoaded = true;
        }
      },
      /**
       * Map over the user objects to return only what we need.
       *
       * @param {Array} users - The list of user objects.
       * @param {string} type - The type of users, either 'carrier' or 'broker'.
       * @returns {Array} The formatted user objects.
       */
      formatUsers(users, type) {
        return users.map(({ user, created_at: createdAt }) => ({
          name: user.full_name,
          company: type === 'carrier' ? user.carrier.name : user.broker.name,
          email: user.email,
          dateAdded: new Date(createdAt).toLocaleDateString('en-US'),
        }));
      },
    },
  };
</script>

<style scoped>
  .contact-tables-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
