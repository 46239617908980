import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * All carrier related ajax calls
 * This was copied over from Admin 1/5/2022
 *
 * @exports services/carrier
 */
export default {
  /**
   * Get a list of Carriers
   *
   * @returns {Promise}
   */
  getCarriers() {
    return new Promise((resolve, reject) => {
      const url = `${normalizedEndpoint}/admin/carriers`;

      axios
        .get(url, axiosConfig())
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * Get the info for a specific carrier
   *
   * @param {number} carrierId
   * @returns {Promise}
   */
  getCarrier(carrierId) {
    return new Promise((resolve, reject) => {
      const url = `${normalizedEndpoint}/admin/carriers/${carrierId}`;

      axios
        .get(url, axiosConfig())
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Get states list
   *
   * @returns {Promise}
   */
  getStatesList() {
    return new Promise((resolve, reject) => {
      const url = `${normalizedEndpoint}/states`;

      axios
        .get(url, axiosConfig())
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Add a new carrier
   *
   * @param {object} data
   * @returns {Promise}
   */
  addNewCarrier(data) {
    return new Promise((resolve, reject) => {
      const url = `${normalizedEndpoint}/admin/carriers`;

      axios
        .post(url, data, axiosConfig())
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * Add new Certified Product Type
   *
   * @param {number} carrierId
   * @param {object} newProductType
   * @returns {Promise}
   */
  addNewProductType(carrierId, newProductType) {
    return new Promise((resolve, reject) => {
      const url = `${normalizedEndpoint}/admin/carriers/${carrierId}/product_types`;

      axios
        .post(url, newProductType, axiosConfig())
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * moves the productType from one carried to another
   *
   * @param {number} currentCarrierId
   * @param {number} targetCarrierId
   * @param {number} productTypeId
   * @returns {object}
   */
  moveProductType(currentCarrierId, targetCarrierId, productTypeId) {
    const url = `${normalizedEndpoint}/admin/product_types/${productTypeId}/move_product_type`;
    const data = { current_carrier_id: currentCarrierId, target_carrier_id: targetCarrierId };

    return axios.post(url, data, axiosConfig());
  },

  /**
   * Update existing Certified Product Type
   *
   * @param {number} carrierId
   * @param {object} productType
   * @param {number} productType.id
   * @param {boolean} productType.certified
   * @param {Array} productType.states
   * @returns {Promise}
   */
  updateProductType(carrierId, { id: productTypeId, certified, states }) {
    return new Promise((resolve, reject) => {
      const url = `${normalizedEndpoint}/admin/carriers/${carrierId}/product_types/${productTypeId}`;
      const updatedProductType = {
        certified,
        state_ids: states.map(({ id }) => id),
      };

      axios
        .patch(url, updatedProductType, axiosConfig())
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Delete a Certified Product Type
   *
   * @param {number} carrierId
   * @param {number} productTypeId
   * @returns {Promise}
   */
  deleteProductType(carrierId, productTypeId) {
    return new Promise((resolve, reject) => {
      const url = `${normalizedEndpoint}/admin/carriers/${carrierId}/product_types/${productTypeId}`;

      axios
        .delete(url, axiosConfig())
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Save updated carrier Info
   *
   * @param {number} carrierId
   * @param {object} data
   * @returns {Promise}
   */
  saveCarrierInfo(carrierId, data) {
    return new Promise((resolve, reject) => {
      const url = `${normalizedEndpoint}/admin/carriers/${carrierId}`;

      axios
        .patch(url, data, axiosConfig())
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get all the users for a specific carrier
   *
   * @param {number} carrierId
   * @returns {Promise}
   */
  getCarrierUsers(carrierId = null) {
    return new Promise((resolve, reject) => {
      const url = `${normalizedEndpoint}/admin/carriers/${carrierId}/users`;

      axios
        .get(url, axiosConfig())
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
