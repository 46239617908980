import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * Retrieve all call outs from a given product using productId. Destructure the data from the response
 * which is a single property called callout that is an array of callouts.
 *
 * @param {number} productId
 * @returns {Promise}
 */
export const readCallouts = async (productId) => {
  const url = `${normalizedEndpoint}/products/${productId}/callouts`;
  const { data: { callouts, callout } } = await axios.get(url, axiosConfig());

  return callouts || callout;
};

/**
 * create a single callout with provided note onto specific plan design value. Destructure the data from the response
 * which is a single property called callout that is the newly created callout.
 *
 * @param {number} productId
 * @param {number} planDesignValueId
 * @param {string} note
 * @returns {Promise}
 */
export const createCallout = async (productId, planDesignValueId, note) => {
  const url = `${normalizedEndpoint}/products/${productId}/callouts`;
  const body = {
    plan_design_value_id: planDesignValueId,
    note,
  };
  const { data: { callout } } = await axios.post(url, body, axiosConfig());

  return callout;
};

/**
 * Updates the callout's read property to true.
 *
 * @param {number} calloutId
 * @returns {Promise} resolves to the callout that was updated
 */
export const patchCalloutRead = async (calloutId) => {
  const url = `${normalizedEndpoint}/callouts/${calloutId}/read`;
  const { data: { callout } } = await axios.patch(url, {}, axiosConfig());

  return callout;
};
