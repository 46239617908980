<template>
  <div
    class="limited-text-area"
    :class="{ error: isOverLimit }"
  >
    <ElInput
      v-model="localValue"
      :rows="rows"
      :placeholder="placeholder"
      type="textarea"
      data-test="limited text area input"
    />
    <span
      class="helper-text"
      data-test="helper text"
    >
      {{ charactersHelperText }}
    </span>
  </div>
</template>

<script>
  /**
   * A text area that limits the number of characters entered.
   * Can also change a v-sync'd boolean prop value if the limit is exceeded
   * via the limitExceeded prop.
   *
   * @exports components/LimitedTextArea
   */
  export default {
    name: 'LimitedTextArea',
    props: {
      /**
       * the string value of the textarea
       */
      value: {
        type: String,
        required: true,
      },
      /**
       * the number of rows to display in the textarea
       */
      rows: {
        type: Number,
        default: 4,
      },
      /**
       * the placeholder text to display in the textarea
       */
      placeholder: {
        type: String,
        default: '',
      },
      /**
       * the maximum number of characters allowed in the textarea
       * users can still type past the limit, but the textarea will be marked as an error
       */
      characterLimit: {
        type: Number,
        default: 256,
        validate: (value) => value > 0,
      },
      /**
       * determines if the textarea limit has been exceeded
       * use a v-sync directive if you want a parent variable
       * to be updated when the textarea limit is exceeded
       */
      limitExceeded: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * displays the number of characters remaining in the text area.
       * also used for additional logic in other computed properties
       *
       * @returns {number}
       */
      charactersRemaining() {
        return this.characterLimit - this.value.length;
      },
      /**
       * determines if the characters entered in the text area exceed the
       * characterLimit prop.
       *
       * @returns {boolean}
       */
      isOverLimit() {
        return this.value.length > this.characterLimit;
      },
      /**
       * formatted text to display in the helper text span in the template
       *
       * @returns {string}
       */
      charactersHelperText() {
        let helperText = `${Math.abs(this.charactersRemaining)} character`;

        // pluralize characters remaining if necessary
        if (Math.abs(this.charactersRemaining) !== 1) {
          helperText += 's';
        }

        helperText += this.isOverLimit ? ' over limit' : ' left';

        return helperText;
      },
      localValue: {
        /**
         * returns the value prop coming from the parent component
         *
         * @returns {string}
         */
        get() {
          return this.value;
        },
        /**
         * emits the tf-input event up to the parent component to update the value
         *
         * @param {string} value
         */
        set(value) {
          this.$emit('input', value);
        },
      },
    },
    watch: {
      /**
       * will update the limitExceeded prop if the characters entered in the text area
       * exceed the characterLimit prop
       *
       * @param {boolean} bool
       */
      isOverLimit(bool) {
        this.$emit('update:limitExceeded', bool);
      },
    },
    /**
     * set limitExceeded to whatever isOverLimit computes to after creation
     */
    created() {
      this.$emit('update:limitExceeded', this.isOverLimit);
    },
  };
</script>

<style lang="scss" scoped>
  .limited-text-area {
    text-align: left;
  }

  .error {
    :deep(textarea) {
      border: 1px solid var(--tf-red);
    }

    span {
      color: var(--tf-red);
    }
  }

  span {
    color: var(--tf-gray);
  }

</style>
