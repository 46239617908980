<template>
  <AppSignIn
    v-bind="{
      ...signInProps,
      userInfo: { email },
    }"
    :email.sync="email"
    :password.sync="password"
    :remember-me.sync="rememberMe"
    :show-sso-method="!!provider || !!authorizationUrl"
  >
    <template #ctaSubmit>
      <AppButton
        :is-disabled="!email || !password"
        :is-loading="isAuthorizing"
        data-test="submit sign in"
        native-type="submit"
        text="Sign in"
        is-block
        @click="signInUser"
      />
    </template>
    <template #ctaSsoSubmit>
      <AppButton
        v-if="provider === 'google_oauth2'"
        :is-loading="isAuthorizing"
        data-test="sign in with google"
        native-type="submit"
        text="Sign in with Google"
        is-block
        @click.prevent="openGoogleOauthPopup"
      />
      <AppButton
        v-else-if="authorizationUrl"
        :is-loading="isAuthorizing"
        data-test="sign in with sso"
        native-type="submit"
        type="primary"
        text="Sign in with SSO"
        is-block
        no-branding
        @click.prevent="redirectToSSO"
      />
      <AppAlert
        v-else-if="provider === 'saml'"
        type="danger"
        data-test="SAML SSO error"
        show-icon
      >
        To access ThreeFlow, you must sign in using your organization's SSO solution.
        If you continue to have issues, contact your workspace administrator or IT department.
      </AppAlert>
      <AppAlert
        v-else
        type="danger"
        data-test="generic SSO error"
        show-icon
      >
        An error occurred showing the SSO options for your specific account.
        Please contact ThreeFlow support.
      </AppAlert>
    </template>
  </AppSignIn>
</template>

<script>
  import { mapState } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import { config } from '@/utils/config.js';
  import AccountService from '@/services/account.js';
  import { workOsCarrierUi } from '@/utils/featureFlags.js';

  /**
   * Sign in
   *
   * @exports Authentication/SignIn
   */
  export default {
    name: 'UserSignIn',
    props: {
      projectId: {
        type: [String, Number],
        default: null,
      },
    },
    data: () => ({
      googleOauthIsLoaded: false,
      googleOauthClient: null,
      googleOauthCode: null,
      provider: null,
      debounceTimer: 0,
      email: '',
      isAuthorizing: false,
      inactivityMessage: null,
      password: '',
      rememberMe: false,
      toast: null,
      authorizationUrl: null,
    }),
    computed: {
      ...mapState(useAccountStore, ['userInfo', 'auth']),
      /**
       * Evaluate the route params and return an object with the appropriate props to pass to appForgotPasswordForm.
       *
       * @returns {object}
       */
      signInProps() {
        const signInProps = {
          linkForgot: { name: 'ForgotPassword' },
          linkRedirect: { name: 'Dashboard' },
          linkRegister: { name: 'RegistrationForm' },
          linkTerms: { name: 'TermsOfUse' },
        };

        if (this.projectId) {
          Object.keys(signInProps).forEach((prop) => {
            signInProps[prop] = {
              name: `Project${signInProps[prop].name}`,
              params: { projectId: this.projectId },
            };
          });

          signInProps.linkRedirect.name = 'RfpOverview';
          signInProps.projectId = this.projectId;
        }

        return signInProps;
      },
      /**
       * Determines if the WorkOS SSO method is enabled.
       *
       * @returns {boolean}
       */
      workOsCarrierUiEnabled() {
        return this.$ld.checkFlags(workOsCarrierUi);
      },
    },
    watch: {
      /**
       * Watch email for value changes on the email address and process these in a debounced fashion.
       *
       * @param {string} newValue
       */
      email(newValue) {
        this.checkUserProvider(newValue);
      },
      'auth.inactive': function (inactivity) {
        if (inactivity) {
          this.showInactivityMessage();
        }
      },
    },
    mounted() {
      const { error } = this.$route.query;

      if (error === 'auth') {
        this.$message.error({
          message: 'Failed to authenticate. Please try again, or contact support.',
          showClose: true,
        });
      }
      if (this.auth.inactive) {
        this.showInactivityMessage();
      }
    },
    /**
     * Sends analytics
     */
    beforeDestroy() {
      // I don't think any of these Toast close methods are working as intended... And they don't work on QA, we need to revisit a bug in which error messages stay open after routing/destroy
      this.inactivityMessage?.close();
      this.toast?.close();

      if (this.auth.confirmed) {
        trackSegmentEvent('Carrier User Sign In Successful', segmentData());
      }

      if (this.googleOauthIsLoaded) {
        this.unloadGoogleOauth();
      }
    },
    methods: {
      checkUserProvider(email) {
        clearTimeout(this.debounceTimer);

        this.debounceTimer = setTimeout(async () => {
          // rff: workOsCarrierUi https://watchtower.atlassian.net/browse/LC-2036
          const { provider, authorization_url: auth } = await AccountService
            .getUserProvider(email, this.workOsCarrierUiEnabled);

          if (this.workOsCarrierUiEnabled && auth) {
            this.authorizationUrl = auth;
          } else {
            this.provider = provider;
          }

          if (provider === 'google_oauth2' && !this.googleOauthIsLoaded) {
            this.loadGoogleOauth();
          }
        }, 500);
      },
      /**
       * Redirect to authorizationUrl.
       */
      redirectToSSO() {
        window.location.assign(this.authorizationUrl);
      },
      /**
       * Sign in the user and redirect them to the appropriate dashboard or redirect query.
       */
      async signInUser() {
        this.isAuthorizing = true;

        try {
          if (this.provider === 'google_oauth2') {
            await AccountService.signInWithGoogle(this.googleOauthCode);
          } else {
            await AccountService.authenticate(
              {
                email: this.email,
                password: this.password,
                project_id: this.projectId,
              },
              this.rememberMe,
            );
          }

          const { redirect } = this.$route.query;

          // Catch edge case and cached legacy issue
          if (redirect && !['/sign-out', '/sign-in'].includes(redirect)) {
            this.$router.replace(redirect);

            return;
          }

          this.$router.replace(this.signInProps.linkRedirect);
        } catch (error) {
          this.toast = this.$message({
            customClass: 'sign-in-message',
            duration: 10000,
            message: error?.response?.data?.message || 'There was an error signing in. Please try again.',
            showClose: true,
            type: 'error',
          });
        } finally {
          this.isAuthorizing = false;
        }
      },
      /**
       * Load GSI script on-demand and initialize OAuth component.
       */
      async loadGoogleOauth() {
        try {
          await this.$loadScript('https://accounts.google.com/gsi/client');

          const client = window.google.accounts.oauth2.initCodeClient({
            client_id: config.VITE_APP_OAUTH_CLIENT_ID,
            scope: 'email profile',
            ux_mode: 'popup',
            callback: this.handleGoogleOauthResponse,
          });

          this.googleOauthClient = client;
          this.googleOauthIsLoaded = true;
        } catch {
          this.$message({
            customClass: 'sign-in-message',
            duration: 10000,
            message: 'An issue occured while trying to load Sign in with Google functionality. Please try again by reloading the page; if the problem persists, please contact support.',
            showClose: true,
            type: 'error',
          });
        }
      },
      /**
       * Unload GSI script before Sign in is destroyed.
       */
      unloadGoogleOauth() {
        this.$unloadScript('https://accounts.google.com/gsi/client');
        this.googleOauthIsLoaded = false;
      },
      /**
       * Trigger popup for Google OAuth sign-in
       */
      openGoogleOauthPopup() {
        this.googleOauthClient.requestCode();
      },
      /**
       * Handle Google OAuth response
       *
       * @param {object} response
       */
      handleGoogleOauthResponse(response) {
        if (response && !response.error) {
          // google authentication succeeded, now process.
          this.googleOauthCode = response.code;
          this.signInUser();
        } else {
          // google authentication failed
          this.$message({
            customClass: 'sign-in-message',
            duration: 10000,
            message: 'An issue occured while trying to sign in with Google. Please try again; if the problem persists, please contact support.',
            showClose: true,
            type: 'error',
          });
        }
      },
      /**
       * If the user was signed out due to inactivity we show a message at the top of the screen explaining why they were signed out
       */
      showInactivityMessage() {
        const h = this.$createElement;
        const message = h('p', { attrs: { class: 'paragraph-message' } }, [
          h('span', null, 'You have been signed out due to inactivity,'),
          h('br', null, null),
          h('span', null, 'or because you\'ve signed in on another device.'),
          h('br', null, null),
          h('span', null, 'All your changes are safe and sound.'),
        ]);

        this.inactivityMessage = this.$message({
          showClose: true,
          message,
          customClass: 'large warning',
          type: 'warning',
          duration: 0,
        });
      },
    },
  };
</script>
