import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * Get broker updates for specified project.
 *
 * @param {number} projectId
 * @returns {Promise} - the broker updates for the project
 */
export const getUpdates = async (projectId) => {
  const url = `${normalizedEndpoint}/projects/${projectId}/revisions`;
  const { data: { project: { revisions } } } = await axios.get(url, axiosConfig());

  return revisions;
};

/**
 * Mark all project revisions as read.
 *
 * @param {number} projectId
 * @returns {Promise}
 */
export const clearNotifications = (projectId) => {
  const url = `${normalizedEndpoint}/notifications/mark_all_project_revisions_as_read`;
  const body = { project_id: projectId };

  return axios.post(url, body, axiosConfig());
};

/**
 * All broker updates/revisions related ajax calls
 *
 * @exports Services/BrokerUpdates
 */
export default {
  getUpdates,
  clearNotifications,
};
