import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * All plan design related ajax calls
 *
 * @exports Services/PlanDesign
 */
export default {
  /**
   * Copy rates from one class (or in force) to a specific class group
   *
   * @param {object} root0
   * @param {number} root0.productId
   * @param {number} root0.sourceId
   * @returns {Promise}
   */
  copyPlanDesign({ productId, sourceId }) {
    const url = `${normalizedEndpoint}/products/${productId}/copy_plan_design_values_from`;

    return axios
      .post(
        url,
        { source_product_id: sourceId },
        axiosConfig(),
      )
      .then(({ data }) => data);
  },
  /**
   * Patch Plan Design for a Product with Preferred Plan Design Values
   *
   * @param {number} productId
   * @returns {Promise}
   */
  applyUserPreferredValues(productId) {
    const url = `${normalizedEndpoint}/products/${productId}/apply_plan_design_user_preferences`;

    return axios
      .patch(url, {}, axiosConfig())
      .then(({ data }) => data);
  },
  /**
   * Get Plan Design for a Product
   *
   * @param {number} productId
   * @returns {Promise}
   */
  getPlanDesign(productId) {
    const url = `${normalizedEndpoint}/products/${productId}/plan_design`;

    return axios
      .get(
        url,
        axiosConfig(),
      )
      .then(({ data }) => data);
  },
  /**
   * Patch Rate Attribute
   *
   * @param {object} planAttribute
   * @returns {Promise}
   */
  patchPlanAttribute(planAttribute) {
    const pdvId = planAttribute.plan_design_attribute_id;
    const url = `${normalizedEndpoint}/plan_design_attributes/${pdvId}/plan_design_values`;

    return axios
      .patch(
        url,
        planAttribute,
        axiosConfig(),
      )
      .then(({ data }) => data);
  },

  /**
   * Validate Rate Structure
   *
   * @param {object} root0
   * @param {number} root0.containerId
   * @param {number} root0.productId
   * @param {number} root0.tierGroupId
   * @returns {Promise}
   */
  patchPlanDesignValueTierGroup({ containerId, productId, tierGroupId }) {
    const url = `${normalizedEndpoint}/products/${productId}/edit_plan_design_value_tier_group`;

    return axios
      .patch(
        url,
        {
          project_product_container_id: containerId,
          tier_group_id: tierGroupId,
        },
        axiosConfig(),
      );
  },

  /**
   * Validate Rate Structure
   *
   * @param {number} productId
   * @param {Function} callback
   * @param {Function} callbackError
   */
  validatePlanDesign(productId, callback, callbackError) {
    const url = `${normalizedEndpoint}/products/${productId}/validate_plan_design`;

    axios
      .patch(
        url,
        {},
        axiosConfig(),
      )
      .then(({ data }) => callback(data))
      .catch(({ response }) => callbackError(response));
  },
};
