import { defineStore } from 'pinia';
import { reusableProjectTypes } from '@watchtowerbenefits/es-utils-public';
import ServiceDocument from '@/services/documents.js';
import ServiceProject from '@/services/project.js';

/* eslint-disable import/no-cycle */
import { useProductStore } from '@/stores/product.js';
import { useProductTableStore } from '@/stores/productTable.js';
/* eslint-enable import/no-cycle */

export const useProjectStore = defineStore('project', {
  state: () => ({
    // broker
    broker: {
      id: null,
      name: null,
      logoUrl: null,
    },
    brokerHierarchy: {},
    brokerIsLoaded: false,
    brokerIsLoading: false,
    // project
    project: {},
    projectIsLoaded: false,
    projectIsLoading: false,
    // documents
    csvQuoteEnabled: false,
    documents: [],
    documentsIsLoaded: false,
    documentsIsLoading: false,
    inforceDocument: null,
    inforceDocumentIsLoaded: false,
    inforceDocumentIsLoading: false,
    proposalDocument: null,
    proposals: null,
    proposalsIsLoaded: false,
    proposalsIsLoading: false,
    waitForDocumentsToProcess: null,
    // notifications
    processingDocumentsNotification: null,
    showSummaryAlert: null,
  }),
  getters: {
    projectId: (state) => state.project.id,
    isRenewalProject: (state) => state.project.type === reusableProjectTypes.renewal,
    employerName: (state) => state.project?.employer?.name ?? null,
    inforceDocumentId: (state) => state.inforceDocument?.id ?? null,
    proposalDocumentId: (state) => state.proposalDocument?.id ?? null,
    autoRenewActivated: (state) => state.proposalDocument?.auto_renew_activated,
  },
  actions: {
    resetProjectState() {
      this.processingDocumentsNotification?.close();
      this.processingDocumentsNotification?.destroyElement();
      if (this.waitForDocumentsToProcess) this.clearDocumentsInterval();
      this.$reset();
    },
    // Broker Actions
    async getBrokerHierarchy(brokerId) {
      try {
        const data = await ServiceProject.brokerHasHierarchy(brokerId);

        this.brokerHierarchy = {
          hasHierarchy: data.broker_has_hierarchy,
          // if no parentId, broker is the top of the hierarchy
          parentId: data.parent_id ?? null,
        };
      } catch {
        this.brokerHierarchy = {};
      }
    },
    async getBrokerInfo(projectId) {
      this.brokerIsLoading = true;
      this.brokerIsLoaded = false;

      try {
        const { broker } = await ServiceProject.getBrokerInfo(projectId);

        this.broker = {
          ...this.broker,
          id: broker.id,
          name: broker.name,
          logoUrl: broker.logo_url || broker.logoUrl,
        };

        await this.getBrokerHierarchy(this.broker.id);
      } finally {
        this.brokerIsLoaded = true;
        this.brokerIsLoading = false;
      }
    },
    // Project Actions
    async getProjectInfo(projectId) {
      this.projectIsLoading = true;
      this.projectIsLoaded = false;

      try {
        const { project } = await ServiceProject.getProjectInfo(projectId);

        this.project = project;
      } finally {
        this.projectIsLoading = false;
        this.projectIsLoaded = true;
      }
    },
    setProjectInfoParameter({ key, value }) {
      this.project[key] = value;
    },
    // Document Actions
    async getAndSetDocuments(projectId, carrierId) {
      const { documents } = await ServiceProject.getProjectDocuments(projectId);

      this.documents = documents;

      this.proposalDocument = this.documents.find(
        (document) => document.carrier?.id === carrierId
          && document.document_type.toLowerCase() === 'proposal',
      ) ?? null;

      this.inforceDocument = this.documents.find(
        (document) => document.document_type.toLowerCase() === 'policy',
      ) ?? null;
    },
    async getProjectDocuments(
      carrierId,
      projectId,
    ) {
      this.documentsIsLoading = true;
      this.documentsIsLoaded = false;

      try {
        await this.getAndSetDocuments(projectId, carrierId);
      } finally {
        this.documentsIsLoading = false;
        this.documentsIsLoaded = true;
      }
    },
    /**
     * Trigger the checkForProcessingDocuments action every 30 seconds to see if the Proposal Document is still
     * processing.
     *
     * @param {number} documentId
     * @param {string} action
     */
    async pollProposalDocumentProcessingState(documentId, action) {
      if (this.waitForDocumentsToProcess) this.clearDocumentsInterval();
      this.waitForDocumentsToProcess = setInterval(() => this.checkForProcessingDocuments(documentId, action), 30000);
    },
    /**
     * Ping the Document endpoint every 30 seconds to see if the Proposal Document is still
     * processing. Updates the ProposalDocument and Documents state with the Proposal Document API
     * response once it is done processing and clear the interval and close the notification box.
     *
     * @param {number} documentId
     * @param {string} action
     */
    async checkForProcessingDocuments(documentId, action) {
      const { document } = await ServiceDocument.getDocumentInfo(documentId);
      const isAutomationLocked = ({ products }) => products.some(({ state }) => state === 'automation_locked');

      if (!isAutomationLocked(document)) {
        this.proposalDocument = document;

        const proposalDocumentIndex = this.documents
          .findIndex(({ id }) => id === this.proposalDocumentId);

        if (proposalDocumentIndex !== -1) {
          this.documents.splice(proposalDocumentIndex, 1, this.proposalDocument);
        }

        useProductStore().setProducts(this.proposalDocument.products);

        this.clearDocumentsInterval();
        this.processingDocumentsNotification?.close();
        this.showSummaryAlert = action || true;
      }
    },
    async getProposalDocument(id) {
      this.proposalsIsLoading = true;
      this.proposalsIsLoaded = false;

      try {
        const { document } = await ServiceDocument.getDocumentInfo(id);

        this.proposals = document.sources;
        this.csvQuoteEnabled = document.carrier.csv_bid_enabled;
        useProductStore().setProducts(document.products);

        if (useProductStore().isProcessingDocuments) {
          this.pollProposalDocumentProcessingState(this.proposalDocumentId);
        }
      } finally {
        this.proposalsIsLoading = false;
        this.proposalsIsLoaded = true;
      }
    },
    async getPolicyDocument(id) {
      this.inforceDocumentIsLoading = true;
      this.inforceDocumentIsLoaded = false;

      try {
        const { document } = await ServiceDocument.getDocumentInfo(id);

        this.inforceDocument = document;
      } finally {
        this.inforceDocumentIsLoading = false;
        this.inforceDocumentIsLoaded = true;
      }
    },
    setDocumentsLoaded(state = false) {
      this.documentsIsLoaded = state;
      this.documentsIsLoading = !state;
    },
    async startDocument() {
      if (this.proposalDocument.state !== 'not_started') {
        return;
      }
      const { document } = await ServiceDocument.startDocument(this.proposalDocumentId);

      this.setProposalDocumentState(document.state);
    },
    setProposalDocumentState(documentState) {
      this.proposalDocument.state = documentState;
    },
    updateProposal(updatedProposal) {
      const index = this.proposals.findIndex(({ id }) => id === updatedProposal.id);

      this.proposals.splice(index, 1, updatedProposal);
    },
    removeProposal(proposalId) {
      const index = this.proposals.findIndex(({ id }) => id === proposalId);

      this.proposals.splice(index, 1);
    },
    setProposals(proposals) {
      this.proposals = proposals;
    },
    setProposalDocument(document = null) {
      this.proposalDocument = document;
    },
    /**
     * This method is used to get the documents and refresh the products
     * when the user submits renewal documnents or declines to quote so the table updates
     * w/o a page refresh
     *
     * The action argument is an option string that is used to determine which alert state to show when processing completes
     *
     * @param {number} carrierId
     * @param {number} projectId
     * @param {string} action
     */
    async getDocumentsAndRefreshProducts(carrierId, projectId, action) {
      try {
        // get project document
        await this.getAndSetDocuments(projectId, carrierId);

        // get proposal document
        const { document } = await ServiceDocument.getDocumentInfo(this.proposalDocumentId);

        this.proposals = document.sources;
        useProductStore().setProducts(document.products);
        if (useProductStore().isProcessingDocuments) {
          this.pollProposalDocumentProcessingState(this.proposalDocumentId, action);
        }
        // get policy documnent
        const { document: policyDocument } = await ServiceDocument.getDocumentInfo(this.inforceDocumentId);

        this.inforceDocument = policyDocument;

        // get project info
        const { project } = await ServiceProject.getProjectInfo(projectId);

        await useProductTableStore().getProductRateGuarantees();

        this.project = project;
      } catch {
        this.$message({
          duration: 10000,
          message: 'There was an error updating the product table. Please refresh the page.',
          showClose: true,
          type: 'error',
        });
      }
    },

    /**
     * Gets the number of unread DIVE failure notifications
     *
     * @returns {number}
     */
    async getUnreadDiveFailureNotificationsCount() {
      const { unread_notifications: notificationCount } = await ServiceProject.getUnreadDiveFailureNotifications();

      return notificationCount || 0;
    },

    /**
     * Gets the number of unread DIVE failure notifications
     *
     * @returns {boolean}
     */
    async markUnreadDiveFailureNotificationsAsRead() {
      return ServiceProject.markUnreadDiveFailureNotificationsAsRead();
    },
    /**
     * Clear Interval for polling documents
     *
     */
    clearDocumentsInterval() {
      clearInterval(this.waitForDocumentsToProcess);
      this.waitForDocumentsToProcess = null;
    },
  },
});
