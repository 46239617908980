import Vue from 'vue';
import { defineStore } from 'pinia';
import { sortObjects } from '@watchtowerbenefits/es-utils-public';

export const useCarriersStore = defineStore('carriers', {
  state: () => ({
    carriers: [],
    currentCarrierId: null,
    currentCarrier: null,
    carrierUsers: [],
  }),

  getters: {
    availableCarriers: ({ carriers }) => carriers.filter(({ disabled }) => !disabled),
    orderedCarriers: ({ carriers }) => sortObjects(carriers), // orders alphabetically
  },

  actions: {
    /**
     * resets the currentCarrier object
     *
     */
    resetCurrentCarrier() {
      this.currentCarrier = null;
      this.currentCarrierId = null;
    },
    /**
     * sets the currentCarrier object
     *
     */
    setCurrentCarrier() {
      this.currentCarrier = this.carriers
        .find((carrier) => +carrier.id === +this.currentCarrierId) ?? null;
    },
    /**
     * sets carriers without 49 (No Prior Coverage Carrier)
     *
     * @param {Array} carriers
     */
    setCarriers(carriers = []) {
      const filteredCarriers = carriers.filter(({ id }) => id !== 49);

      this.carriers = filteredCarriers;
    },
    /**
     * updates carrier
     *
     * @param {object} carrier
     */
    updateCarrier(carrier) {
      const carrierId = carrier.id;
      const carrierIndex = this.carriers.findIndex(({ id }) => id === carrierId);

      Vue.set(this.carriers, carrierIndex, carrier);
    },
  },
});

export default {
  useCarriersStore,
};
