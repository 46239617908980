<template>
  <div
    v-loading="isLoading"
    :class="[
      { 'is-panel' : isPanel },
      'comments-container'
    ]"
  >
    <template v-if="isLoaded">
      <CommentsHeader />
      <TfCommentsExport
        :broker="broker"
        :comments="comments"
        :documents="[proposalDocument]"
        :employer-name="employerName"
        :project-id="Number(projectId)"
        :is-disabled="!comments.length"
        :user-info="commentUserInfo"
        button-title="Export comments"
        data-test="export comments"
        @click.native="sendAnalytics()"
      />
      <div
        v-if="comments.length"
        class="comments-list-container"
      >
        <comment
          v-for="comment in comments"
          :key="comment.id"
          application-namespace="carrier_portal"
          :broker="broker"
          :comment="comment"
          :project-id="projectId"
          :proposal-document-id="proposalDocumentId"
          :show-new-badge="showNewBadge"
          :user-info="commentUserInfo"
          @commentAdded="addReply($event[0])"
        />
      </div>
      <div
        v-else
        class="empty-state-container"
      >
        <div class="empty-state">
          <AppIcon
            icon="fa-solid fa-comments"
          />
          <p>Send comments or questions to {{ broker.name }}.</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import { segmentData } from '@/utils/analytics.js';
  import { useCommentsStore } from '@/stores/comments.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useAccountStore } from '@/stores/account.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import CommentsHeader from './CommentsHeader.vue';

  /**
   * Comments container
   *
   * @exports CommentsContainer
   */
  export default {
    name: 'CommentsContainer',
    components: { CommentsHeader },
    computed: {
      ...mapState(useCommentsStore, [
        'comments',
        'showNewBadge',
        'isLoaded',
        'isLoading',
      ]),
      ...mapState(useProjectStore, [
        'broker',
        'project',
        'projectId',
        'employerName',
        'proposalDocumentId',
        'proposalDocument',
        'proposalsIsLoaded',
      ]),
      ...mapState(useAccountStore, ['commentUserInfo']),
      /**
       * Evaluates the pageType meta property from the current route to determine if this is a panelled page or not.
       *
       * @returns {boolean}
       */
      isPanel() {
        return this.$route.meta.pageType === 'dataEntry';
      },
    },
    watch: {
      proposalsIsLoaded: {
        handler() {
          if (this.proposalsIsLoaded) {
            this.getComments(this.proposalDocumentId);
            this.clearCommentNotifications(this.$route.params.projectId);
          }
        },
        immediate: true,
      },
    },
    /**
     * On creation we need to clear any session comments from other projects
     * and clear out any comment notifications
     */
    created() {
      this.clearSessionComments();
    },
    /**
     * when we exit out of the comments panel, we want to clear all new badges assuming they've been read
     */
    beforeDestroy() {
      if (this.isPanel) {
        this.setShowNewBadge();
      }
    },

    methods: {
      ...mapActions(useCommentsStore, ['addReply', 'getComments', 'setShowNewBadge', 'clearCommentNotifications']),
      /**
       *
       * Clears out the sessionStorage replied comments that aren't saved before the project loads. We put it here to avoid moving it later.
       */
      clearSessionComments() {
        // This one clears any unsaved replies
        const comments = Object.keys(window.sessionStorage)
          .filter((key) => key.startsWith('comment-'))
          .reduce((acc, key) => ({
            ...acc,
            [key]: JSON.parse(window.sessionStorage.getItem(key)),
          }), {});

        Object.values(comments).forEach((comment) => {
          if (parseInt(this.projectId, 10) !== parseInt(comment.projectId, 10)) {
            window.sessionStorage.removeItem(comment);
          }
        });
      },
      /**
       * Sends event to segment
       */
      sendAnalytics() {
        trackSegmentEvent('Export comments', segmentData());
      },
    },
  };
</script>

<style lang="scss" scoped>
  .comments-container {
    padding: 0 20px;

    &.is-panel {
      text-align: left;
      padding: 18px 33px;
      min-width: 100vw;
      box-sizing: border-box;

      @media (min-width: 557px) {
        min-width: 557px;
      }
    }
  }

  .empty-state-container {
    display: flex;
    align-self: center;
    flex-grow: 1;
    width: 100%;
    min-height: 200px;
  }

  .empty-state {
    text-align: center;
    margin: 0 auto;
    align-self: center;
    padding: 10px 0;
    color: var(--tf-gray-light-medium);

    svg {
      width: 107px;
      height: 83px;
      margin: 0 auto 22px;
    }

    p {
      font-size: 18px;
      line-height: 1.33;
      color: var(--tf-gray);
      max-width: 448px;
      margin: 0 auto;
    }
  }
</style>
