<script setup>
  // eslint-disable-next-line import/extensions
  import { useRoute } from 'vue-router/composables';

  const route = useRoute();
</script>
<template>
  <h1>
    Medical Quotes ID: {{ route.params.id }}
  </h1>
</template>
<style scoped>
h1 {
  text-align: center;
}
</style>
