import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { useAccountStore } from '@/stores/account.js';
import router from '@/router.js';
import Vue from 'vue';
import { setContext } from '@sentry/vue';
import { useLaunchDarklyStore } from '@/stores/launchDarkly.js';
import { parseLDConfig, initializeLD } from '@/utils/launchDarkly.js';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;

export default {
  /**
   * does a bunch of mock stuff for axios interceptor
   */
  activate() {
    // create a list of api calls that we need to mock and the responses we want
    const environment = process.env.NODE_ENV;

    if (environment === 'testing') {
      const mock = new MockAdapter(axios);

      mock.onGet(`${normalizedEndpoint}/edit_history/document/1508`).reply(200, {
        document: {
          id: 4445,
          edit_history: [
            {
              id: 25197,
              edit_history_item_id: 22416,
              document_id: 4445,
              product_id: 21309,
              project_id: 620,
              attributable_type: 'PlanDesignAttribute',
              attributable_id: 67,
              viewable: true,
              label: null,
              before_value: null,
              after_value: '36',
              product_type_name: 'Short Term Disability',
              first_created_at: '2019-07-15T18:19:13.792279',
              last_created_at: '2019-09-11T16:39:45.544574',
              created_at: '2019-09-11T16:40:06.692083',
              updated_at: '2019-09-11T16:40:06.692083',
              is_new: false,
              alternative: false,
              history_details: {
                id: 22416,
                tier_subtype_id: null,
                tier_group_id: null,
                user_id: 107,
                version_id: 3997450,
                attributable_type: 'PlanDesignAttribute',
                attributable_id: 67,
                editable_type: 'PlanDesignValue',
                editable_id: 350139,
                rate_values: null,
                plan_design_value: '36',
                container_number: 2,
                version_timestamp: '2019-09-11T16:39:45.544574',
                created_at: '2019-09-11T16:40:06.689875',
                updated_at: '2019-09-11T16:40:06.689875',
                attribute_name: 'Rate Guarantee',
                container_name: '2',
                container_type: 'Class',
                container_type_name: 'Class',
                tier_subtype_name: null,
                responsible_user: {
                  id: 107,
                  carrier: {
                    id: 9,
                    name: 'Guardian',
                    logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/logos/guardian/original/guardian-large_3x.png?1566618674',
                    small_logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/small_logos/guardian/small_logo/guardian-icon_3x.png?1566618674',
                  },
                  last_name: '_',
                  first_name: 'Admin',
                },
              },
            },
            {
              id: 25196,
              edit_history_item_id: 22415,
              document_id: 4445,
              product_id: 21309,
              project_id: 620,
              attributable_type: 'PlanDesignAttribute',
              attributable_id: 67,
              viewable: true,
              label: null,
              before_value: null,
              after_value: '36',
              product_type_name: 'Short Term Disability',
              first_created_at: '2019-07-15T18:19:13.792279',
              last_created_at: '2019-09-11T16:39:44.6676',
              created_at: '2019-09-11T16:40:06.084013',
              updated_at: '2019-09-11T16:40:06.084013',
              is_new: false,
              alternative: false,
              history_details: {
                id: 22415,
                tier_subtype_id: null,
                tier_group_id: null,
                user_id: 107,
                version_id: 3997449,
                attributable_type: 'PlanDesignAttribute',
                attributable_id: 67,
                editable_type: 'PlanDesignValue',
                editable_id: 349896,
                rate_values: null,
                plan_design_value: '36',
                container_number: 1,
                version_timestamp: '2019-09-11T16:39:44.6676',
                created_at: '2019-09-11T16:40:06.081156',
                updated_at: '2019-09-11T16:40:06.081156',
                attribute_name: 'Rate Guarantee',
                container_name: '1',
                container_type: 'Class',
                container_type_name: 'Class',
                tier_subtype_name: null,
                responsible_user: {
                  id: 107,
                  carrier: {
                    id: 9,
                    name: 'Guardian',
                    logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/logos/guardian/original/guardian-large_3x.png?1566618674',
                    small_logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/small_logos/guardian/small_logo/guardian-icon_3x.png?1566618674',
                  },
                  last_name: '_',
                  first_name: 'Admin',
                },
              },
            },
            {
              id: 25195,
              edit_history_item_id: 22414,
              document_id: 4445,
              product_id: 21309,
              project_id: 620,
              attributable_type: 'RateAttribute',
              attributable_id: 10,
              viewable: true,
              label: 'Composite',
              before_value: null,
              after_value: '0.4',
              product_type_name: 'Short Term Disability',
              first_created_at: '2019-08-14T14:23:15.740939',
              last_created_at: '2019-09-11T16:39:47.71108',
              created_at: '2019-09-11T16:40:05.504426',
              updated_at: '2019-09-11T16:40:05.504426',
              is_new: false,
              alternative: false,
              history_details: {
                id: 22414,
                tier_subtype_id: null,
                tier_group_id: null,
                user_id: 107,
                version_id: 3997452,
                attributable_type: 'RateAttribute',
                attributable_id: 10,
                editable_type: 'RateValue',
                editable_id: 13742,
                rate_values: [
                  {
                    label: 'composite',
                    value: 0.4,
                    volume: 428617,
                    display_label: 'Composite',
                    comparison_flag: 'deviation_detected',
                  },
                ],
                plan_design_value: null,
                container_number: 1,
                version_timestamp: '2019-09-11T16:39:47.71108',
                created_at: '2019-09-11T16:40:05.50154',
                updated_at: '2019-09-11T16:40:05.50154',
                attribute_name: 'Short Term Disability Rate',
                container_name: '1',
                container_type: 'Class',
                container_type_name: 'Class',
                tier_subtype_name: null,
                responsible_user: {
                  id: 107,
                  carrier: {
                    id: 9,
                    name: 'Guardian',
                    logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/logos/guardian/original/guardian-large_3x.png?1566618674',
                    small_logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/small_logos/guardian/small_logo/guardian-icon_3x.png?1566618674',
                  },
                  last_name: '_',
                  first_name: 'Admin',
                },
              },
            },
            {
              id: 25194,
              edit_history_item_id: 22413,
              document_id: 4445,
              product_id: 21309,
              project_id: 620,
              attributable_type: 'RateAttribute',
              attributable_id: 10,
              viewable: true,
              label: 'Composite',
              before_value: null,
              after_value: '0.4',
              product_type_name: 'Short Term Disability',
              first_created_at: '2019-08-14T14:23:15.77127',
              last_created_at: '2019-09-11T16:39:54.909351',
              created_at: '2019-09-11T16:40:02.101092',
              updated_at: '2019-09-11T16:40:02.101092',
              is_new: false,
              alternative: false,
              history_details: {
                id: 22413,
                tier_subtype_id: null,
                tier_group_id: null,
                user_id: 107,
                version_id: 3997456,
                attributable_type: 'RateAttribute',
                attributable_id: 10,
                editable_type: 'RateValue',
                editable_id: 13748,
                rate_values: [
                  {
                    label: 'composite',
                    value: 0.4,
                    volume: null,
                    display_label: 'Composite',
                    comparison_flag: 'deviation_detected',
                  },
                ],
                plan_design_value: null,
                container_number: 2,
                version_timestamp: '2019-09-11T16:39:54.909351',
                created_at: '2019-09-11T16:40:01.677543',
                updated_at: '2019-09-11T16:40:01.677543',
                attribute_name: 'Short Term Disability Rate',
                container_name: '2',
                container_type: 'Class',
                container_type_name: 'Class',
                tier_subtype_name: null,
                responsible_user: {
                  id: 107,
                  carrier: {
                    id: 9,
                    name: 'Guardian',
                    logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/logos/guardian/original/guardian-large_3x.png?1566618674',
                    small_logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/small_logos/guardian/small_logo/guardian-icon_3x.png?1566618674',
                  },
                  last_name: '_',
                  first_name: 'Admin',
                },
              },
            },
          ],
        },
      });
      mock.onGet(`${normalizedEndpoint}/projects/999/info`).reply(200, {
        project: {
          due_date: '2017-11-30',
          locked: false,
          status: 0,
          employer: {
            id: 353,
            name: 'Anixter - TBC',
            sic_code: null,
            total_lives: null,
          },
          rfp_info: {
            employer_name: 'Corix',
            employer_location: ' 53226',
            employer_website: 'none provided',
            employer_total_lives: 161,
            employer_sic: '4939',
            products: 'Basic Life / AD&D, Long Term Disability, Optional Life, Short Term Disability',
            incumbent_carriers: 'Anthem',
            broker_name: 'Hays Companies of Illinois',
            broker_of_record_label: 'Hays Companies of Illinois is not the Broker of Record',
            broker_contacts: '',
            reason_for_rfp: '',
            benefit_administrator: 'none provided',
            market_activity: '0 Pending',
            effective_date: '2019-01-01',
            project_due_date: 'Due Date Not Specified',
            rfp_cover_memo: 'none provided',
            inforce_sources_message: 'Plan Design files are staged and uploaded to the Carrier Site',
          },
          invited_carriers: 2,
          submitted_carriers: 1,
        },
      });
      mock.onAny(`${normalizedEndpoint}/file-upload`).reply(200, {
      });
      mock.onGet(`${normalizedEndpoint}/users/invited_to_project?email=test@test.com&project_id=999`).reply(200, {
        invited: true,
      });
      mock.onGet(`${normalizedEndpoint}/products/7012/rate_structure`).reply(200, {});

      const userResponse = {
        user: {
          id: 158,
          first_name: 'Post',
          last_name: 'Man',
          email: 'something_new@watchtowerbenefits.com',
          confirmed: false,
          auth_token: 'eiJPTfygBZaceseGviKWJQ',
          auth_token_expires_at: '2020-10-16T02:01:18.246668',
          last_login_at: null,
          last_logout_at: null,
          magic_link_token: null,
          magic_link_token_expires_at: null,
          carrier_portal_view: '{}',
          roles: [{ id: 3, name: 'carrier' }],
          carrier: {
            id: 9,
            name: 'Guardian',
            logo_url: null,
          },
        },
      };

      mock.onPost(`${normalizedEndpoint}/sign_in`).reply(200, {
        user: {
          id: 80,
          first_name: 'test',
          last_name: 'test',
          email: 'test@test.com',
          confirmed: true,
          confirmation_token: '-LmlaeaENTOlaCRGyhSIjw',
          confirmation_token_expires_at: '2099-10-16T02:01:18.246668',
          auth_token: 'eiJPTfygBZaceseGviKWJQ',
          auth_token_expires_at: '2099-10-16T02:01:18.246668',
          last_login_at: '2017-10-15T18:01:18.246911',
          last_logout_at: '2017-10-13T18:17:05.093391',
          magic_link_token: null,
          magic_link_token_expires_at: null,
          temp_password: false,
          department: null,
          roles: [{ id: 3, name: 'carrier' }],
          carrier_portal_view: '{\'rates_started_for_product_type_7\':true,\'plan_started_for_product_type_7\':true,\'plan_started_for_product_type_2\':true,\'rates_started_for_product_type_2\':true,\'rates_started_for_product_type_4\':true,\'plan_started_for_product_type_4\':true,\'rates_started_for_product_type_9\':true,\'rates_started_for_product_type_5\':true,\'plan_started_for_product_type_5\':true,\'plan_started_for_product_type_8\':true,\'plan_started_for_product_type_9\':true,\'plan_started_for_product_type_11\':true,\'rates_started_for_product_type_11\':true,\'rates_started_for_product_type_8\':true,\'rates_started_for_product_type_3\':true,\'plan_started_for_product_type_3\':true,\'rates_started_for_product_type_1\':true,\'plan_started_for_product_type_1\':true}',
          carrier: {
            id: 9,
            name: 'Guardian',
            logo_url: null,
            small_logo_url: null,
          },
        },
      });
      mock.onPost(`${normalizedEndpoint}/users`).reply(200, userResponse);
      mock.onGet(`${normalizedEndpoint}/users/80`).reply(200, userResponse);
      mock.onPut(`${normalizedEndpoint}/reset_password`).reply(200, {
        message: 'Password has been changed',
      });
      mock.onPost(`${normalizedEndpoint}/forgot_password`).reply(200, {
        message: 'Temporary password has been sent',
      });
      mock.onPost(`${normalizedEndpoint}/sign_out`).reply(200, {
        logged_out: 'string',
      });
      mock.onPost(`${normalizedEndpoint}/send_confirmation`).reply(200, {
      });
      mock.onPost(`${normalizedEndpoint}/confirm`).reply(200, {
        user: {
          id: 158,
          first_name: 'Post',
          last_name: 'Man',
          email: 'something_new@watchtowerbenefits.com',
          confirmed: false,
          auth_token: 'eiJPTfygBZaceseGviKWJQ',
          auth_token_expires_at: '2017-10-16T02:41:16.556103',
          last_login_at: null,
          last_logout_at: null,
          magic_link_token: null,
          magic_link_token_expires_at: null,
          carrier_portal_view: '{}',
          roles: [{ id: 3, name: 'carrier' }],
          carrier: {
            id: 9,
            name: 'Guardian',
            logo_url: null,
          },
        },
      });
      mock.onGet(`${normalizedEndpoint}/projects/999/broker_info`).reply(200, {
        broker: {
          id: 4,
          name: 'Lockton-Chicago',
          logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/broker_logos/lockton.png',
        },
      });
      mock.onGet(`${normalizedEndpoint}/projects/999/documents`).reply(200, {
        documents: [
          {
            id: 1657,
            project_id: 371,
            document_type: 'Proposal',
            state: 'data_entry',
            is_sold: false,
            created_at: '2017-09-27T21:17:52.331452',
            updated_at: '2017-10-31T03:17:30.710077',
            project_product: {
              inforce_carrier_id: 3,
            },
            carrier: {
              id: 3,
              name: 'MetLife',
              logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/carrier_logos/metlife.jpg',
            },
          },
          {
            id: 1508,
            project_id: 371,
            document_type: 'Proposal',
            state: 'data_entry',
            is_sold: false,
            created_at: '2017-08-23T04:54:58.954654',
            updated_at: '2017-11-03T23:36:07.646811',
            project_product: {
              inforce_carrier_id: 9,
            },
            carrier: {
              id: 9,
              name: 'Guardian',
              logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/carrier_logos/guardian.jpg',
            },
          },
          {
            id: 1362,
            project_id: 371,
            document_type: 'Policy',
            state: 'finalized',
            is_sold: false,
            created_at: '2017-07-16T15:41:28.957133',
            updated_at: '2017-07-18T00:03:11.302455',
            products: [
              {
                id: 7010,
                product_type_id: 2,
                project_product: {
                  inforce_carrier_id: 9,
                },
                carrier: {
                  id: 9,
                  name: 'Guardian',
                  logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/carrier_logos/guardian.jpg',
                },
              },
              {
                id: 1209,
                product_type_id: 7,
                project_product: {
                  inforce_carrier_id: 9,
                },
                carrier: {
                  id: 9,
                  name: 'Guardian',
                  logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/carrier_logos/guardian.jpg',
                },
              },
            ],
            project_product: {
              inforce_carrier_id: 5,
            },
            carrier: {
              id: 5,
              name: 'Reliance Standard',
              logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/carrier_logos/reliance_standard.jpg',
            },
          },
        ],
      });
      mock.onGet(`${normalizedEndpoint}/documents/1362`).reply(200, {
        document: {
          id: 1362,
          project_id: 584,
          document_type: 'Policy',
          state: 'finalized',
          renewal: false,
          is_sold: false,
          created_at: '2018-09-12T20:53:39.021686',
          updated_at: '2018-09-12T20:55:41.704928',
          carrier: {
            id: 8,
            name: 'Cigna',
            logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/carrier_logos/cigna.jpg',
          },
          products: [
            {
              id: 7010,
              project_product: {
                inforce_carrier_id: 9,
              },
              carrier: {
                id: 9,
                name: 'Guardian',
                logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/carrier_logos/guardian.jpg',
              },
            },
            {
              id: 1209,
              project_product: {
                inforce_carrier_id: 9,
              },
              carrier: {
                id: 9,
                name: 'Guardian',
                logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/carrier_logos/guardian.jpg',
              },
            },
          ],
          sources: [
            {
              id: 6206,
              created_at: '2018-09-12T20:56:05.06989',
              updated_at: '2018-09-12T20:56:05.06989',
              file_file_name: 'name_to_give_saved_file_(3).pdf',
              file_content_type: 'application/pdf',
              file_file_size: 115820,
              document_id: 3737,
              name: 'name_to_give_saved_file_(3).pdf',
              type: 'ExperienceSource',
              carrier_id: null,
              user_id: 361,
              original_source_id: null,
              user: {
                id: 361,
                email: 'domenicroti@watchtowerbenefits.com',
                first_name: 'test',
                last_name: 'Roti',
                carrier: {},
                broker: {
                  id: 1,
                  name: 'ThreeFlow',
                  logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/broker_logos/watchtower.png',
                },
              },
            },
          ],
        },
      });
      mock.onGet(`${normalizedEndpoint}/projects/999/revisions`).reply(200, {
        project: {
          id: 385,
          revisions: [],
        },
      });
      mock.onGet(`${normalizedEndpoint}/documents/1508`).reply(200, {
        document: {
          id: 371,
          project_id: 157,
          document_type: 'Proposal',
          state: 'finalized',
          is_sold: false,
          created_at: '2015-11-17T18:07:17.5931',
          updated_at: '2016-03-25T13:21:26.993628',
          carrier: {
            id: 4,
            name: 'Lincoln',
            csv_bid_enabled: true,
            logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/carrier_logos/lincoln.jpg',
          },
          products: [
            {
              id: 1512,
              document_id: 1122,
              state: 'not_started',
              carrier_id: 9,
              is_sold: false,
              document_type: 'Proposal',
              project_product_id: 667,
              valid_plan_design: false,
              valid_rate_structure: true,
              position: 1,
              archived: false,
              completed_at: null,
              product_type_name: 'Stop Loss',
              document_state: 'data_entry',
              project_product: {
                id: 667,
                product_type_id: 1,
                alternative: false,
                label: 'Stop Loss',
                contributory: true,
                prior_coverage: true,
                position: 10,
                sold_status: 'Not Sold',
                inforce_carrier_id: 1,
                sold_carrier_id: null,
                hidden_options: {},
                product_type_name: 'Stop Loss',
                inforce_carrier_name: 'Sun Life',
                sold_carrier_name: null,
                inforce_product: {
                  id: 6646,
                  document_id: 1544,
                  state: 'completed',
                  carrier_id: 1,
                  is_sold: false,
                  document_type: 'Policy',
                  project_product_id: 667,
                  valid_plan_design: true,
                  valid_rate_structure: true,
                  position: 1,
                  archived: false,
                  carrier: {
                    id: 1, name: 'Sun Life', logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/l ogos/sun_life/original/sun_life.jpg?1538140524',
                  },
                },
              },
            },
            {
              id: 1209,
              document_id: 1603,
              state: 'not_started',
              carrier_id: 9,
              is_sold: false,
              document_type: 'Proposal',
              project_product_id: 454,
              valid_plan_design: false,
              valid_rate_structure: true,
              position: 3,
              archived: false,
              completed_at: null,
              product_type_name: 'Basic Life / AD&D',
              document_state: 'data_entry',
              project_product: {
                id: 454,
                product_type_id: 2,
                alternative: false,
                label: 'Basic Life / AD&D',
                contributory: true,
                prior_coverage: true,
                position: 10,
                sold_status: 'Not Sold',
                inforce_carrier_id: 1,
                sold_carrier_id: null,
                hidden_options: {},
                product_type_name: 'Basic Life / AD&D',
                inforce_carrier_name: 'Sun Life',
                sold_carrier_name: null,
                inforce_product: {
                  id: 6646,
                  document_id: 1544,
                  state: 'completed',
                  carrier_id: 1,
                  is_sold: false,
                  document_type: 'Policy',
                  project_product_id: 454,
                  valid_plan_design: true,
                  valid_rate_structure: true,
                  position: 1,
                  archived: false,
                  carrier: {
                    id: 1, name: 'Sun Life', logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/l ogos/sun_life/original/sun_life.jpg?1538140524',
                  },
                },
              },
            },
            {
              id: 7010,
              document_id: 371,
              state: 'not_started',
              carrier_id: 9,
              is_sold: false,
              document_type: 'Proposal',
              project_product_id: 454,
              valid_plan_design: false,
              valid_rate_structure: true,
              position: 3,
              archived: false,
              completed_at: null,
              product_type_name: 'Long Term Disability',
              document_state: 'data_entry',
              project_product: {
                id: 454,
                product_type_id: 7,
                alternative: false,
                label: 'Long Term Disability',
                contributory: true,
                prior_coverage: true,
                position: 10,
                sold_status: 'Not Sold',
                inforce_carrier_id: 1,
                sold_carrier_id: null,
                hidden_options: {},
                product_type_name: 'Long Term Disability',
                inforce_carrier_name: 'Sun Life',
                sold_carrier_name: null,
                inforce_product: {
                  id: 6646,
                  document_id: 1544,
                  state: 'completed',
                  carrier_id: 1,
                  is_sold: false,
                  document_type: 'Policy',
                  project_product_id: 454,
                  valid_plan_design: true,
                  valid_rate_structure: true,
                  position: 1,
                  archived: false,
                  carrier: {
                    id: 1, name: 'Sun Life', logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/l ogos/sun_life/original/sun_life.jpg?1538140524',
                  },
                },
              },
            },
          ],
          sources: [
          ],
        },
      });
      mock.onPatch(`${normalizedEndpoint}/documents/1508/decline`).reply(200, {
        document: {
          id: 371,
          project_id: 157,
          document_type: 'Proposal',
          state: 'declined',
          is_sold: false,
          created_at: '2015-11-17T18:07:17.5931',
          updated_at: '2016-03-25T13:21:26.993628',
          carrier: {
            id: 4,
            name: 'Lincoln',
            logo_url: 'https://s3-us-west-2.amazonaws.com/watchtower-public/carrier_logos/lincoln.jpg',
          },

          products: [
            {
              id: 1209,
              document_id: 1603,
              state: 'declined',
              carrier_id: 9,
              is_sold: false,
              document_type: 'Proposal',
              project_product_id: 454,
              valid_plan_design: false,
              valid_rate_structure: true,
              position: 3,
              archived: false,
              completed_at: null,
              product_type_name: 'Basic Life / AD&D',
              document_state: 'data_entry',
              project_product: {
                id: 454,
                product_type_id: 2,
                alternative: false,
                label: 'Basic Life / AD&D',
                contributory: true,
                prior_coverage: true,
                position: 10,
                sold_status: 'Not Sold',
                inforce_carrier_id: 1,
                sold_carrier_id: null,
                hidden_options: {},
                product_type_name: 'Basic Life / AD&D',
                inforce_carrier_name: 'Sun Life',
                sold_carrier_name: null,
                inforce_product: {
                  id: 6646,
                  document_id: 1544,
                  state: 'completed',
                  carrier_id: 1,
                  is_sold: false,
                  document_type: 'Policy',
                  project_product_id: 454,
                  valid_plan_design: true,
                  valid_rate_structure: true,
                  position: 1,
                  archived: false,
                  carrier: {
                    id: 1, name: 'Sun Life', logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/l ogos/sun_life/original/sun_life.jpg?1538140524',
                  },
                },
              },
            },
            {
              id: 7010,
              document_id: 371,
              state: 'declined',
              carrier_id: 9,
              is_sold: false,
              document_type: 'Proposal',
              project_product_id: 454,
              valid_plan_design: false,
              valid_rate_structure: true,
              position: 3,
              archived: false,
              completed_at: null,
              product_type_name: 'Long Term Disability',
              document_state: 'data_entry',
              project_product: {
                id: 454,
                product_type_id: 7,
                alternative: false,
                label: 'Long Term Disability',
                contributory: true,
                prior_coverage: true,
                position: 10,
                sold_status: 'Not Sold',
                inforce_carrier_id: 1,
                sold_carrier_id: null,
                hidden_options: {},
                product_type_name: 'Long Term Disability',
                inforce_carrier_name: 'Sun Life',
                sold_carrier_name: null,
                inforce_product: {
                  id: 6646,
                  document_id: 1544,
                  state: 'completed',
                  carrier_id: 1,
                  is_sold: false,
                  document_type: 'Policy',
                  project_product_id: 454,
                  valid_plan_design: true,
                  valid_rate_structure: true,
                  position: 1,
                  archived: false,
                  carrier: {
                    id: 1, name: 'Sun Life', logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/l ogos/sun_life/original/sun_life.jpg?1538140524',
                  },
                },
              },
            },
          ],
          sources: [],
        },
      });
      mock.onGet(`${normalizedEndpoint}/products/1209`).reply(200, {
        product: {
          id: 7012,
          document_id: 1508,
          state: 'not_started',
          carrier_id: 9,
          is_sold: false,
          document_type: 'Proposal',
          // 'project_product_id': 454,
          valid_plan_design: false,
          valid_rate_structure: false,
          // 'position': 3,
          archived: false,
          prior_coverage: true,
          completed_at: null,
          product_type_id: 7,
          product_type_name: 'Long Term Disability',
          inforce_carrier_id: 9,
          inforce_carrier_name: 'Sun Life',
          inforce_carrier_logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/logos/sun_life/original/sun_life.jpg?1538140524',
          available_plan_design_tier_groups: [
            { id: 6, name: 'PPO', selected: false },
            { id: 10, name: 'DHMO', selected: false },
          ],
          rate_guarantee: {
            id: 16,
            name: 'Rate Guarantee',
            category_id: 6,
            position: 90,
            created_at: '2015-06-12T18:25:53.308748',
            updated_at: '2015-07-30T21:32:40.784539',
            dynamic_attribute_id: 49,
            product_type_id: 7,
            value: '1',
          },
          project_product: {
            id: 454,
            product_type_id: 7,
            alternative: false,
            label: 'Long Term Disability',
            contributory: false,
            prior_coverage: true,
            position: 10,
            sold_status: 'Not Sold',
            inforce_carrier_id: 9,
            sold_carrier_id: null,
            hidden_options: {},
            product_type_name: 'Long Term Disability',
            inforce_carrier_name: 'Sun Life',
            sold_carrier_name: null,
            inforce_product: {
              id: 6646,
              document_id: 1544,
              state: 'completed',
              carrier_id: 9,
              is_sold: false,
              document_type: 'Policy',
              project_product_id: 454,
              valid_plan_design: true,
              valid_rate_structure: true,
              position: 1,
              archived: false,
              carrier: {
                id: 9,
                name: 'Sun Life',
                logo_url: 'https://watchtower-public.s3.amazonaws.com/carriers/logos/sun_life/original/sun_life.jpg?1538140524',
              },
            },
          },
        },
      });
      mock.onGet(`${normalizedEndpoint}/products/1209/normalized_values`).reply(200, {
        product: {
          id: 20961,
          document_id: 1508,
          state: 'editing',
          carrier_id: 9,
          is_sold: false,
          document_type: 'Proposal',
          project_product_id: 1214,
          valid_plan_design: true,
          valid_rate_structure: true,
          position: 4,
          archived: false,
          product_type_name: 'Basic Life / AD&D',
          attributes: [
            {
              id: 192, name: 'Employer', category_id: 2, position: 10, normalized_values: [],
            },
          ],
        },
      });
      mock.onGet(`${normalizedEndpoint}/product_types/2/tier_groups`).reply(200, {
        tier_groups: {
          rate_tier_groups: [],
          plan_design_tier_groups: [],
        },
      });
    }

    axios.interceptors.response.use(
      (response) => {
        // We let the healthCheck call handle this itself, since it runs before the store is initialized.
        // regex looks for any url that contains "core-api" and doesn't contain "health_check"
        if (response.config.url.match(/.*core-api((?!health_check|provider).)*$/)) {
          const parsedConfiguration = parseLDConfig(response);
          const launchDarklyStore = useLaunchDarklyStore();

          launchDarklyStore.config = parsedConfiguration;
          const ldInitialized = !!Vue.prototype.$ld;

          if (!ldInitialized) {
            initializeLD(parsedConfiguration);
          }
        }

        return response;
      },
      (error) => {
        setContext('Axios Error', error);

        // we should also be checking that this came from our api server (and not a third party api
        if ([401, 403].includes(error?.response?.status)) {
          // we can remove this if we change the sign in error code
          if (
            !error.response.config.url.includes('v1/carrier_portal/sign_in')
            && useAccountStore().auth.confirmed
          ) {
            this.inactiveLogout();
          }
        }

        return Promise.reject(error);
      },
    );
  },
  /**
   * Method that adds the LogRocket SessionURL to the headers each time an
   * Axios Request is made
   *
   * @param {string} sessionURL
   */
  addLogRocketURLToHeaders(sessionURL) {
    axios.interceptors.request.use((requestConfig) => ({
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
        'X-LogRocket-URL': sessionURL,
      },
    }));
  },
  /**
   * Call the logout service and then redirect the user to the sign in page
   * This gets passed down to the ThreeFlow plugin so it can call it too
   */
  inactiveLogout() {
    const {
      fullPath,
      name,
      params: { projectId },
      query: { redirect },
    } = router.history.current;
    const query = {
      redirect: redirect || fullPath,
    };
    let route = { name: 'SignOut' };

    if (['SignOut', 'ProjectSignOut'].includes(name)) {
      return;
    }

    if (projectId) {
      route = {
        name: 'ProjectSignOut',
        params: { projectId },
      };
    }

    router.push({ ...route, query });
  },
};
