<template>
  <div class="rfp-quote-updates-tab tab-content">
    <BrokerUpdatesContainer />
    <QuoteEditsContainer heading="Quote edits" />
  </div>
</template>

<script>
  // pinia
  import { mapState, mapWritableState } from 'pinia';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useProjectStore } from '@/stores/project.js';
  // components
  import QuoteEditsContainer from '@/components/QuoteEdits/QuoteEditsContainer.vue';
  import BrokerUpdatesContainer from '@/components/BrokerUpdates/BrokerUpdatesContainer.vue';
  import { clearEditNotifications } from '@/services/notifications.js';

  /**
   * RFP Overview/Quote Updates
   *
   * @exports RfpOverview/UpdatesEdits
   */
  export default {
    name: 'UpdatesEdits',
    components: {
      QuoteEditsContainer,
      BrokerUpdatesContainer,
    },
    computed: {
      ...mapWritableState(useNotificationsStore, ['unreadEdits']),
      ...mapState(useProjectStore, ['projectId']),
    },
    /**
     * clear all quote edit notifications if there are any
     */
    beforeDestroy() {
      if (this.unreadEdits) {
        this.clearQuoteEditsNotifications();
      }
    },

    methods: {
      /**
       * if the quote edits container is opened we should clear out the notifications for this user
       */
      clearQuoteEditsNotifications() {
        clearEditNotifications(this.projectId)
          .then(() => {
            this.unreadEdits = null;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .rfp-quote-updates-tab {
    position: relative;
    flex-grow: 1;

    @media (min-width: 650px) {
      display: flex;
      flex-direction: row;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      border-right: 1px solid var(--tf-gray-light-medium);
      content: '';
    }
  }

  .broker-updates-container,
  .history-updates-container {
    width: 50%;
  }

  .broker-updates-container {
    padding: 38px 55px 0 20px;
  }

  .history-updates-container {
    padding: 38px 20px 0 55px;
  }
</style>
