import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * All carrier insights related ajax calls
 *
 * @exports services/insight
 */

/**
 * Fetches looker url
 *
 * @param {number} carrierId
 * @returns {Promise} axios Promise
 */
export const getLookerUrl = ({ dashboardType, embedDomain }) => {
  const url = `${normalizedEndpoint}/insights/embed_url?dashboard_id=looker_external_client::external_${dashboardType}&embed_domain=${embedDomain}`;

  return axios.get(url, axiosConfig())
    .then(({ data: { embed_url: embedUrl } }) => embedUrl);
};

export default { getLookerUrl };
