<template>
  <div class="rfp-column">
    <slot name="title" />
    <section class="document-manager">
      <section v-loading="!inforceDocument">
        <header>
          <AppButton
            icon="fa-solid fa-file-download"
            size="text"
            text="Download all files"
            data-test="download all files"
            @click="onDownload('downloadAll')"
          />
        </header>
        <div id="rfp-documents">
          <!-- Plan Summary Documents -->
          <RfpDocumentCategory
            key="Plan summary documents"
            title="Plan summary"
          >
            <AppButton
              :is-loading="isDownloadingPlanSummary"
              data-test="download file button"
              icon="fa-solid fa-file-download"
              size="text"
              text="plan_summary.xlsx"
              @click="onDownload('downloadPlanSummary')"
            />
          </RfpDocumentCategory>
          <!-- Census Documents -->
          <RfpDocumentCategory
            v-if="censusSources.sources.length"
            key="Census documents"
            title="Census"
            :file-list="censusSources.sources"
            @downloaded="onDownload('startDocument')"
          />
          <!-- Stop Loss Documents -->
          <RfpDocumentSection
            v-if="stopLossSources.some(sourceType => sourceType.sources.length)"
            :source-group="stopLossSources"
            title="Stop loss"
            @downloaded="onDownload('startDocument')"
          />
          <!-- Non-Medical Documents -->
          <RfpDocumentSection
            v-if="nonMedicalSources.some(sourceType => sourceType.sources.length)"
            :source-group="nonMedicalSources"
            title="Non-medical"
            @downloaded="onDownload('startDocument')"
          />
        </div>
      </section>
    </section>
    <!-- This is a hidden link to download the inforce packet triggered with `onDownloadAll` -->
    <a
      id="download-all-link"
      ref="downloadAll"
      :href="project.inforce_packet_url"
      :download="`${employerName.replace(/ /g, '_')}_inforce_packet.zip`"
      value="Download"
      target="_blank"
      data-test="hidden download all files"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import { useProjectStore } from '@/stores/project.js';
  import ProjectService from '@/services/project.js';
  import RfpDocumentCategory from '@/components/RfpDocuments/RfpDocumentCategory.vue';
  import RfpDocumentSection from '@/components/RfpDocuments/RfpDocumentSection.vue';
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

  /**
   * RFP Documents
   *
   * @exports RfpDocuments
   */
  export default {
    name: 'RfpDocuments',
    components: { RfpDocumentCategory, RfpDocumentSection },
    data: () => ({
      isDownloadingPlanSummary: false,
    }),
    computed: {
      ...mapState(useProjectStore, [
        'employerName',
        'inforceDocument',
        'project',
        'projectId',
        'proposalDocument',
        'proposalDocumentId',
      ]),
      /**
       * Array of Census Sources.
       *
       * @returns {Array}
       */
      censusSources() {
        return {
          sources: this.inforceDocument?.sources.filter(({ type }) => type.toLowerCase() === 'censussource') ?? [],
          title: 'Census',
        };
      },
      /**
       * Array of Stop Loss Sources.
       *
       * @returns {Array}
       */
      nonMedicalSources() {
        return this.inforceDocument?.sources
          ? [
            {
              sources: this.inforceDocument.sources.filter((source) => source.type === 'PolicySource'),
              title: 'Non-medical policy / certificates',
              type: 'PolicySource',
            },
            {
              sources: this.inforceDocument.sources.filter((source) => source.type === 'RateSource'),
              title: 'Non-medical rates and enrollment',
              type: 'RateSource',
            },
            {
              sources: this.inforceDocument.sources.filter((source) => source.type === 'ExperienceSource'),
              title: 'Non-medical experience',
              type: 'ExperienceSource',
            },
          ]
          : [];
      },
      /**
       * Array of Stop Loss Sources.
       *
       * @returns {Array}
       */
      stopLossSources() {
        return this.inforceDocument?.sources
          ? [
            {
              sources: this.inforceDocument.sources.filter((source) => source.type === 'StopLoss::PolicySource'),
              title: 'Stop loss policy / Certificates',
              type: 'StopLoss::PolicySource',
            },
            {
              sources: this.inforceDocument.sources.filter((source) => source.type === 'StopLoss::RateSource'),
              title: 'Stop loss rates and enrollment',
              type: 'StopLoss::RateSource',
            },
            {
              sources: this.inforceDocument.sources.filter((source) => source.type === 'StopLoss::ExperienceSource'),
              title: 'Stop loss experience',
              type: 'StopLoss::ExperienceSource',
            },
          ]
          : [];
      },
    },
    methods: {
      ...mapActions(useProjectStore, ['startDocument']),
      /**
       * Generate and download the plan summary excel file.
       */
      async downloadPlanSummary() {
        this.isDownloadingPlanSummary = true;
        const filename = 'plan_summary.xlsx';

        try {
          const data = await ProjectService.getPlanSummary(this.projectId);
          const u8 = new Uint8Array(data);
          const blob = new Blob([u8], { type: 'application/octet-stream' });

          this.isDownloadingPlanSummary = false;

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
          }
          this.startDocument();
        } catch {
          this.$message({
            type: 'error',
            message: 'There was a problem downloading the file. Please try again.',
          });
          this.isDownloadingPlanSummary = false;
        }
      },

      /**
       * Click the hidden "Download All" button.
       */
      downloadAll() {
        this.$refs.downloadAll.click();
        this.startDocument();
      },

      /**
       * Calls analytics then runs the necessary callback per download type
       *
       * @param {Function} callbackName - what method to call to initiate download
       */
      onDownload(callbackName) {
        const analyticsEventTitle = {
          downloadAll: 'Download all files RFP info',
          downloadPlanSummary: 'Download plan summary',
        };

        if (callbackName !== 'startDocument') {
          trackSegmentEvent(analyticsEventTitle[callbackName], segmentData());
        }

        this[callbackName]();
      },
    },
  };
</script>

<style lang="scss" scoped>
  p {
    margin: 0 0 20px;
  }

  header {
    padding: 28px 0;
    background-color: var(--tf-gray-light);
    text-align: center;
  }

  .document-manager {
    border: 1px var(--tf-gray-light-medium) solid;
    background: #fff;
  }

  #download-all-link {
    display: none;
  }

  .rfp-column {
    margin: 0 20px 23px;

    @media (min-width: 1024px) {
      width: calc(50% - 40px);
    }
  }

  .rfp-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: var(--carrier-max-width);
    margin: 0 auto;

    @media (min-width: 1024px) {
      flex-flow: row wrap;
    }
  }

  #rfp-documents {
    padding: 12px 18px;
  }

  :deep(.rfp-table) {
    td {
      font-size: 14px;
    }
  }
</style>
