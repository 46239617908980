// RFP Overview views
import Contacts from '@/views/RfpOverview/EventsContact.vue';
import Comments from '@/views/RfpOverview/ProjectOverviewComments.vue';
import MarketActivity from '@/views/RfpOverview/MarketActivity.vue';
import RfpOverview from '@/views/RfpOverview/index.vue';
import UpdatesEdits from '@/views/RfpOverview/UpdatesEdits.vue';
import RfpInfo from '@/views/RfpOverview/RfpInfo.vue';
import YourQuotes from '@/views/RfpOverview/YourQuotes.vue';
// Dashboard views
import DashboardsContainer from '@/views/Dashboards/index.vue';
import YourOpportunities from '@/views/Dashboards/YourOpportunities.vue';
import AllOpportunities from '@/views/Dashboards/AllOpportunities.vue';
import CarrierInsights from '@/views/Dashboards/Insights.vue';
// Authentication views
import EmailConfirmation from '@/views/Authentication/EmailConfirmation.vue';
import ForgotPassword from '@/views/Authentication/ForgotPassword.vue';
import UserSignIn from '@/views/Authentication/SignIn.vue';
import SsoSignIn from '@/views/Authentication/SsoSignIn.vue';
import UserSignOut from '@/views/Authentication/SignOut.vue';
import UserRegistration from '@/views/Authentication/Register.vue';
import RegisterConfirmation from '@/views/Authentication/RegisterConfirmation.vue';
import ResendConfirmation from '@/views/Authentication/ResendConfirmation.vue';
import TermsOfUse from '@/views/TermsOfUse.vue';
// Product views
import PlanDesignRenewal from '@/views/ProductLanding/PlanDesignRenewalLanding.vue';
import ProductLanding from '@/views/ProductLanding/index.vue';
import RateEntryRenewal from '@/views/ProductLanding/RateEntryRenewalLanding.vue';
// Carrier Management views
import CarrierManagement from '@/views/CarrierManagement/index.vue';
import AddCarrier from '@/views/CarrierManagement/Carrier/Add.vue';
import EditCarrier from '@/views/CarrierManagement/Carrier/Edit.vue';
import EditCarrierUsers from '@/views/CarrierManagement/Carrier/Users.vue';
import EditCarrierSettings from '@/views/CarrierManagement/Carrier/Settings.vue';
import EditCarrierDomains from '@/views/CarrierManagement/Carrier/Domains.vue';
import EditCarrierProductTypes from '@/views/CarrierManagement/Carrier/ProductTypes.vue';
// Quote (Proposal/Renewal) Submission view
import QuoteSubmission from '@/views/QuoteSubmission.vue';
// Medical views
import MedicalQuotes from '@/views/Medical/MedicalQuotes.vue';
// Misc views
import NotFound from '@/components/NotFound.vue';
import EditUser from '@/views/EditUser.vue';
import { getCookie } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

/**
 * Checks users cookies to determine if user should be sent to dashboard/rfpoverview/or desired route
 *
 * @param {object} to
 * @param {object} from
 * @param {Function} next
 */
function requiredNotAuth(to, from, next) {
  const { projectId } = to.params;
  const auth = window.localStorage.getItem('auth') === 'true';
  const authCookie = getCookie(`${config.VUE_APP_COOKIE_NAMESPACE}-auth-token`);
  const isRoot = !to.name && !from.name;
  const isSignIn = to.name && ['ProjectSignIn', 'SignIn'].includes(to.name);
  const redirectToEntryPage = isRoot || isSignIn;

  // User is coming directly to a sign-in/ProjectSignIn and is still signed in.
  if (
    auth
    && authCookie
    && redirectToEntryPage
  ) {
    let route = { name: 'Dashboard' };

    if (projectId) {
      route = {
        name: 'RfpOverview',
        params: { projectId },
      };
    }

    next(route);

    return;
  }

  next();
}
/**
 * checks for a stored confirmed value to determine if route should be sent to sign-in or not
 *
 * @param {object} to
 * @param {object} from
 * @param {Function} next
 */
function requireNotConfirmed(to, from, next) {
  if (window.localStorage.getItem('confirmed') === 'true') {
    next({
      name: 'ProjectSignIn',
      params: { projectId: to.params.projectId },
    });

    return;
  }

  next();
}

// these page titles repeat in several places so this lets them be slightly more DRY
const panelAliasTitles = {
  comments: 'Comments',
  'quote-edits': 'Quote edits',
  'broker-updates': 'RFP updates',
};
/**
 * "rootAuthenticationRoutes" and "projectAuthenticationRoutes" are the same routes
 * except with a different name and path/params.
 */
const rootAuthenticationRoutes = [
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: requiredNotAuth,
    meta: {
      pageType: 'authentication',
      title: 'Forgot password',
      noAuth: true,
    },
  },
  // Registration pages
  {
    path: '/register',
    name: 'RegistrationForm',
    component: UserRegistration,
    beforeEnter: requireNotConfirmed,
    meta: {
      pageType: 'authentication',
      title: 'Create a carrier account',
      noAuth: true,
    },
  },
  {
    path: '/register/confirmation',
    name: 'RegisterConfirmation',
    component: RegisterConfirmation,
    meta: {
      pageType: 'authentication',
      title: 'Almost done!',
      noAuth: true,
    },
  },
  {
    path: '/register/confirmation/email',
    name: 'ResendConfirmation',
    component: ResendConfirmation,
    beforeEnter: requireNotConfirmed,
    meta: {
      pageType: 'authentication',
      title: 'Confirm email',
      noAuth: true,
    },
  },
  {
    path: '/register/confirmation/email/:token',
    name: 'EmailConfirmation',
    component: EmailConfirmation,
    beforeEnter: requireNotConfirmed,
    meta: {
      pageType: 'authentication',
      title: 'Email confirmation',
      noAuth: true,
    },
  },
];
const projectAuthenticationRoutes = rootAuthenticationRoutes.map((route) => ({
  ...route,
  path: `/project/:projectId${route.path}`,
  name: `Project${route.name}`,
}));

export const routes = [
  ...rootAuthenticationRoutes,
  ...projectAuthenticationRoutes,
  {
    path: '/',
    component: UserSignIn,
    beforeEnter: requiredNotAuth,
    meta: {
      pageType: 'authentication',
      title: 'Sign in',
      noAuth: true,
    },
  },
  {
    path: '/renewal/submit/:token',
    name: 'RenewalSubmission',
    props: (route) => ({
      token: route.params.token,
      quoteType: 'renewal',
    }),
    component: QuoteSubmission,
    meta: {
      title: 'Renewal Submission',
      pageType: 'quoteSubmission',
      noAuth: true,
    },
  },
  {
    path: '/proposal/submit/:token',
    name: 'ProposalSubmission',
    props: (route) => ({
      token: route.params.token,
      quoteType: 'proposal',
    }),
    component: QuoteSubmission,
    meta: {
      title: 'Proposal Submission',
      pageType: 'quoteSubmission',
      noAuth: true,
    },
  },
  {
    path: '/support',
    name: 'Support',
    /**
     * opens a new window to support page
     */
    beforeEnter() {
      window.open(
        'https://support.threeflow.com/en',
        '_blank',
      );
    },
  },
  // Authentication pages
  {
    path: '/terms',
    name: 'TermsOfUse',
    beforeEnter: requiredNotAuth,
    component: TermsOfUse,
    meta: {
      pageType: 'authentication',
      title: 'Terms of use',
      noAuth: true,
    },
  },
  {
    path: '/sign-in',
    alias: '/login',
    name: 'SignIn',
    component: UserSignIn,
    beforeEnter: requiredNotAuth,
    meta: {
      pageType: 'authentication',
      title: 'Sign in',
      noAuth: true,
    },
  },
  {
    path: '/sso',
    name: 'SsoSignIn',
    component: SsoSignIn,
    beforeEnter: requiredNotAuth,
    meta: {
      pageType: 'authentication',
      title: 'Authenticating with SSO',
      noAuth: true,
    },
  },
  {
    path: '/sign-out',
    name: 'SignOut',
    component: UserSignOut,
    meta: {
      pageType: 'authentication',
      title: 'Sign out',
    },
  },
  {
    component: DashboardsContainer,
    name: 'Dashboard',
    path: '/dashboard',
    redirect: '/dashboard/your-opportunities',
    meta: {
      pageType: 'dashboard',
      title: 'Dashboard',
      allow: [
        'carrier_executive',
        'carrier',
      ],
    },
    children: [
      {
        component: YourOpportunities,
        name: 'YourOpportunities',
        path: 'your-opportunities/:pageSelected?/:sortProp?/:sortDirection?/:filters?',
        meta: {
          pageType: 'dashboard',
          title: 'Dashboard',
        },
      },
      {
        component: AllOpportunities,
        name: 'AllOpportunities',
        path: 'all-opportunities/:pageSelected?/:sortProp?/:sortDirection?/:filters?',
        meta: {
          pageType: 'dashboard',
          title: 'Dashboard',
        },
      },
      {
        component: CarrierInsights,
        name: 'Insights',
        path: 'insights',
        meta: {
          pageType: 'dashboard',
          title: 'Dashboard',
          allow: [
            'carrier_executive',
          ],
        },
      },
    ],
  },
  // Project SignIn
  {
    path: '/project/:projectId/',
    alias: ['/project/:projectId/sign-in'],
    name: 'ProjectSignIn',
    component: UserSignIn,
    beforeEnter: requiredNotAuth,
    meta: {
      pageType: 'authentication',
      title: 'Sign in',
      noAuth: true,
      allow: [
        'carrier_executive',
        'carrier',
      ],
    },
    props: true,
  },
  {
    path: '/project/:projectId/sign-out',
    name: 'ProjectSignOut',
    component: UserSignOut,
    meta: {
      pageType: 'authentication',
      title: 'Sign out',
      allow: [
        'carrier_executive',
        'carrier',
      ],
    },
  },
  // RFP Overview tabs
  {
    name: 'RfpOverview',
    path: '/project/:projectId/rfpOverview',
    redirect: '/project/:projectId/rfpOverview/your-quotes',
    component: RfpOverview,
    meta: {
      allow: [
        'carrier_executive',
        'carrier',
      ],
    },
    children: [
      {
        component: YourQuotes,
        name: 'YourQuotes',
        path: 'your-quotes',
        meta: {
          title: 'Your quotes',
        },
      },
      {
        component: RfpInfo,
        name: 'RfpInfo',
        path: 'rfp-info',
        meta: {
          title: 'RFP info',
        },
      },
      {
        component: UpdatesEdits,
        name: 'UpdatesEdits',
        path: 'updates-edits',
        meta: {
          title: 'Updates & edits',
        },
      },
      {
        component: MarketActivity,
        name: 'MarketActivity',
        path: 'market-activity',
        meta: {
          title: 'Market activity',
        },
      },
      {
        component: Comments,
        name: 'Comments',
        path: 'comments',
        meta: {
          title: 'Comments',
        },
      },
      {
        component: Contacts,
        name: 'Contacts',
        path: 'contacts',
        meta: {
          title: 'Contacts',
        },
        props: true,
      },
    ],
  },
  // Product Based Components
  {
    component: ProductLanding,
    name: 'ProductLanding',
    path: '/project/:projectId/product/:productId',
    meta: {
      allow: [
        'carrier_executive',
        'carrier',
      ],
    },
    props: true,
    children: [
      {
        path: 'rate',
        alias: [
          'rate/quote-edits',
          'rate/comments',
          'rate/broker-updates',
        ],
        name: 'RateEntry',
        component: RateEntryRenewal,
        meta: {
          pageType: 'dataEntry',
          aliasTitles: {
            root: 'Rate entry',
            ...panelAliasTitles,
          },
        },
        children: [
          {
            path: 'review',
            alias: [
              'review/quote-edits',
              'review/comments',
              'review/broker-updates',
            ],
            name: 'RateEntryReview',
            component: RateEntryRenewal,
            meta: {
              pageType: 'dataEntry',
              readonly: true,
              aliasTitles: {
                root: 'Rate entry review',
                ...panelAliasTitles,
              },
            },
          },
        ],
      },
      {
        path: 'plan',
        alias: [
          'plan/quote-edits',
          'plan/comments',
          'plan/broker-updates',
        ],
        name: 'PlanDesign',
        component: PlanDesignRenewal,
        meta: {
          pageType: 'dataEntry',
          aliasTitles: {
            root: 'Plan design',
            ...panelAliasTitles,
          },
        },
        children: [
          {
            path: 'review',
            alias: [
              'review/quote-edits',
              'review/comments',
              'review/broker-updates',
            ],
            name: 'PlanDesignReview',
            component: PlanDesignRenewal,
            meta: {
              pageType: 'dataEntry',
              readonly: true,
              aliasTitles: {
                root: 'Plan design review',
                ...panelAliasTitles,
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '/carrier-management',
    name: 'CarrierManagement',
    component: CarrierManagement,
    meta: {
      pageType: 'management',
      allow: ['admin'],
    },
  },
  {
    path: '/carrier-management/new',
    name: 'AddCarrier',
    props: true,
    component: AddCarrier,
    meta: {
      pageType: 'management',
      allow: ['admin'],
    },
    redirect: { name: 'AddCarrierSettings' },
    children: [
      {
        path: 'settings',
        name: 'AddCarrierSettings',
        component: EditCarrierSettings,
        meta: { pageType: 'management' },
      },
    ],
  },
  {
    path: '/carrier-management/:carrierId',
    name: 'EditCarrier',
    props: true,
    component: EditCarrier,
    meta: {
      pageType: 'management',
      allow: ['admin'],
    },
    redirect: { name: 'EditCarrierUsers' },
    children: [
      {
        path: 'users',
        name: 'EditCarrierUsers',
        component: EditCarrierUsers,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'users/new',
        name: 'AddCarrierUser',
        /**
         * the props to pass to this route rendered component
         *
         * @param {object} route
         * @returns {object} the props that will be passed to the rendered component
         */
        props(route) {
          return {
            orgId: route.params.carrierId,
            showBreadcrumb: false,
            userType: 'carrier',
          };
        },
        component: EditUser,
        meta: { pageType: 'management' },
      },
      {
        path: 'product_types',
        name: 'EditCarrierProductTypes',
        component: EditCarrierProductTypes,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'domains',
        name: 'EditCarrierDomains',
        component: EditCarrierDomains,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'settings',
        name: 'EditCarrierSettings',
        component: EditCarrierSettings,
        meta: { pageType: 'management' },
        props: true,
      },
      {
        path: 'users/:userId',
        name: 'EditCarrierUser',
        /**
         * the props to pass to this route rendered component
         *
         * @param {object} route
         * @returns {object} the props that will be passed to the rendered component
         */
        props(route) {
          return {
            orgId: route.params.carrierId,
            showBreadcrumb: false,
            userId: route.params.userId,
            userType: 'carrier',
          };
        },
        component: EditUser,
        meta: { pageType: 'management' },
      },
    ],
  },
  // Medical Quotes
  {
    component: MedicalQuotes,
    path: '/medical/quotes/:id',
    name: 'MedicalQuotes',
  },
  {
    path: '*',
    component: NotFound,
  },
];

export default routes;
