<template>
  <!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
  <td
    :colspan="subtypeColspan[subtypeType] / subtypeLength"
    :class="[
      readonly ? 'cell-read-only' : 'cell-show-input',
      { 'is-smart-proposal': isSmartProposal,
        'warning-border': warningBorder,
        'remove-error': removeError,
      }
    ]"
    class="plan-design-value-cell"
    :data-test="`plan design value cell ${subtypeType}`"
  >
    <div
      v-if="cellData.name"
      class="subtype-name"
    >
      {{ cellData.name }}
    </div>
    <div :class="{ 'plan-design-input': !readonly }">
      <ElFormItem
        v-if="!readonly"
        :prop="formKey"
        :show-message="!hideError"
        :class="[
          { highlighted : showHighlight },
          { 'hide-error': hideError }
        ]"
        size="medium"
        label-width="0"
        data-test="plan design value"
      >
        <TfInputWithOptions
          v-model="valueModel"
          v-bind="{
            appendValidationType: ['percentage-whole-number'].includes(validationType),
            autoCollapse: false,
            options: normalizedValues,
            validationType
          }"
          placeholder="Enter benefit or provision"
          prop="benefit provision"
          data-test="benefit provision"
        />
      </ElFormItem>
      <span
        v-else
        v-text="valueModel || '--'"
      />
      <div class="callout">
        <CpCallout
          v-if="canShowCallout"
          :callouts="callouts"
          :visible.sync="calloutVisible"
          :append-to-body="false"
          @addCallout="handleAddCallout"
        >
          <template #reference>
            <AppButton
              :class="{
                'has-callouts': callouts.length,
                'callout-button': true,
              }"
              :icon="icon"
              type="primary"
              size="icon"
              :data-test="`open callout for ${attributeName.toLowerCase()}`"
              @click="toggleCallout"
            />
          </template>
        </CpCallout>
      </div>
    </div>
    <p
      v-if="!readonly && isSmartProposal && sourceValue"
      data-test="source value"
      class="source-value"
    >
      {{ sourceValue }}
    </p>
  </td>
</template>

<script>
  import { mapActions, mapState, mapWritableState } from 'pinia';
  import { useCallouts } from '@/stores/callouts.js';
  import { useUserPreferredPdvsStore } from '@/stores/userPreferredPlanDesignValues.js';
  import useContainerStore from '@/stores/planDesign/containers.js';
  import { useProductStore } from '@/stores/product.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  import { sourceMap } from '@/utils/planDesignValues.js';

  /**
   * Plan Design Table Cell Value
   *
   * @exports PlanDesign/PlanDesignTdValue
   */
  export default {
    name: 'PlanDesignTdValue',
    inject: [
      'containerId',
      'isPlanSummary',
      'subtypeColspan',
      'selectedSource',
    ],
    props: {
      /**
       * ID of the Attribute used to grab the store value
       */
      attributeId: {
        type: Number,
        default: 0,
      },
      /**
       * Name of the Attribute used to populate data-test ID
       */
      attributeName: {
        type: String,
        default: null,
      },
      /**
       * ID of the Category used to grab the store value
       */
      categoryId: {
        type: Number,
        default: 0,
      },
      /**
       * Value information
       */
      cellData: {
        type: Object,
        required: true,
        default: () => ({}),
      },
      /**
       * Form key for validation
       */
      formKey: {
        type: String,
        default: null,
      },
      /**
       * Used to determine if input or paragraph is used.
       */
      readonly: {
        type: Boolean,
        required: true,
        default: false,
      },
      /**
       * Used to determine if the ID is from joined class IDs or from single Attribute
       */
      rolledOut: {
        type: Number,
        default: 0,
      },
      /**
       * Used to determine colspan length.
       */
      subtypeType: {
        type: String,
        default: 'policy',
      },
      /**
       * Used to determine if the colspan should be 1 or match the subtype Colspan determined at a higher level.
       */
      subtypeLength: {
        type: Number,
        default: 1,
      },
      /**
       * ID of the Group used to grab the store value
       */
      tierGroupId: {
        type: Number,
        default: null,
      },
      /**
       * ID of the Subtype used to grab the store value
       */
      tierSubtypeId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        timer: 0,
        isInitValue: true,
        calloutVisible: false,
      };
    },
    computed: {
      ...mapState(usePlanDesignStore, ['planDesignIsPlan']),
      ...mapWritableState(usePlanDesignStore, ['values']),

      ...mapState(useCallouts, {
        oldCallouts: 'callouts',
        calloutMap: 'calloutMap',
      }),
      ...mapState(useUserPreferredPdvsStore, ['userPreferredPlanDesignValues']),
      ...mapState(useContainerStore, ['highlightedMap']),
      ...mapState(useProductStore, { allNormalizedValues: 'normalizedValues' }),
      ...mapState(useProductStore, [
        'productId',
        'productState',
        'isUploadRenewalRatePass',
        'isSmartProposal',
        'planDesignErrors',
        'isProductNotStartedOrNotSubmitted',
      ]),
      /**
       * Used to determine attributeNormalizedValues length.
       *
       * @returns {Array}
       */
      attributeNormalizedValues() {
        if (this.isPlanSummary) {
          return {};
        }

        return this.allNormalizedValues.find((values) => values.id === this.attributeId) || {};
      },
      /**
       * Gets the first callout that was made by a carrier, or the first callout that has
       * neither a carrier nor a broker.
       *
       * This is to be as backwards compatible as we can
       * with the new benefit comments feature since there can be some callouts that have
       * a thread of comments and we need to determine which single callout to show.
       *
       * @returns {object|undefined}
       */
      callout() {
        return this.oldCallouts
          .filter(({ plan_design_value_id: pdvId, carrier, broker }) => (
            pdvId === this.planDesignValue.id && (carrier || !broker)
          ))
          .shift();
      },
      /**
       * Gets all the callouts that are associated with this plan design value id.
       *
       * @returns {Array}
       */
      callouts() {
        return this.calloutMap[this.planDesignValue.id] ?? [];
      },
      /**
       * We need to know if we have a callout.note for showing on the review page
       *
       * @returns {boolean}
       */
      canShowCallout() {
        if (this.subtypeType === 'policy') {
          return false;
        }

        return true;
      },
      /**
       * ID of value object in the store.
       * `${containerId}_${categoryId}_${attributeId}`_${tierGroupId}_${tierSubtypeId}`
       *
       * @returns {string}
       */
      id() {
        let containerIds = this.planDesignIsPlan
          ? this.cellData.plan_design_value.container_id
          : this.cellData.plan_design_values.map((value) => value.container_id).join('_');

        if (this.rolledOut) {
          containerIds = this.rolledOut;
        }

        let id = `${containerIds}_${this.categoryId}_${this.attributeId}`;

        if (this.tierGroupId && this.tierSubtypeId) {
          id += `_${this.tierGroupId}_${this.tierSubtypeId}`;
        }

        return id;
      },
      /**
       * In-Force Value used for live deviation testing and for matching in-force value
       *
       * @returns {string}
       */
      inforceValue() {
        return this.values[this.id].inforceValue;
      },
      /**
       * Source value.
       *
       * @returns {string}
       */
      sourceValue() {
        const source = this.values[this.id].automated_by?.value;

        if (!source) return null;

        if (source === 'not_found') return this.isProductNotStartedOrNotSubmitted ? null : 'Missing Information';

        return `${sourceMap[source]}`;
      },
      /**
       * Hide the error state for not started or not submitted products
       * that have not been altered by the user.
       *
       * @returns {boolean}
       */
      hideError() {
        return this.isInitValue
          && this.isProductNotStartedOrNotSubmitted
          && this.planDesignValue.value === this.valueModel;
      },
      /**
       * Shows warning border.
       *
       * @returns {boolean}
       */
      warningBorder() {
        return (
          !this.isProductNotStartedOrNotSubmitted
          && this.isSmartProposal
          && this.values[this.id].automated_by?.value === 'not_found'
        );
      },
      /**
       * Removes error.
       *
       * @returns {boolean}
       */
      removeError() {
        return (
          this.isProductNotStartedOrNotSubmitted
          && this.isSmartProposal
          && this.values[this.id].automated_by?.value === 'not_found'
          && (this.values[this.id].value === ''
            || this.values[this.id].value === null
            || this.values[this.id].value === '--'
          )
        );
      },
      /**
       * Highlights based on smartProposal status, isDeviated if false or if source equals the selected source.
       * Missing Information source will not show if status is not submitted or started so dont highlight.
       *
       * @returns {boolean}
       */
      showHighlight() {
        if (!this.isSmartProposal) return this.isDeviated;
        const source = this.values[this.id].automated_by?.value;

        if (source === 'not_found' && this.selectedSource === source) return !this.isProductNotStartedOrNotSubmitted;

        return this.selectedSource === source;
      },
      /**
       * Checks if a value is a deviation from an in-force value
       *
       * @returns {boolean}
       */
      isDeviated() {
        return this.highlightedMap[this.containerId]
          && this.inforceValue !== this.valueModel;
      },
      /**
       * Changes the icon based on if a plan design value has a callout
       * and whether or not that callout has been read by the broker.
       *
       * @returns {string}
       */
      icon() {
        // No callouts shows the add callout icon.
        if (!this.callouts.length) {
          return 'fa-solid fa-note-medical';
        }

        const carrierNotes = this.callouts.filter(({ carrier }) => carrier);

        // If there are broker callouts that have not been read, show the exclamation icon.
        if (this.callouts.some(({ broker, read }) => broker && !read)) {
          return 'fa-kit fa-tf-note-exclamation';
        }

        // If all carrier callouts are read, show the check icon.
        if (carrierNotes.length && carrierNotes.every(({ read }) => read)) {
          return 'fa-kit fa-tf-note-check';
        }

        // Default to filled sticky note, otherwise.
        return 'fa-solid fa-note-sticky';
      },
      /**
       * Maps a new array of normalized values to include "Match In-force" if there is a in-force value.
       *
       * @returns {Array}
       */
      normalizedValues() {
        const hasValues = this.attributeNormalizedValues.normalized_values
          && this.attributeNormalizedValues.normalized_values.length;
        let normalizedValues = [];

        // map normalized values array if any exists
        if (hasValues) {
          normalizedValues = this.attributeNormalizedValues.normalized_values.map(
            (value) => ({ normalized_value: value.value }),
          );
        }

        if (this.inforceValue) {
          normalizedValues.unshift({
            normalized_label: 'Match in-force',
            normalized_value: this.inforceValue,
          });
        }

        return normalizedValues;
      },
      /**
       * Value Object used for various states and data.
       *
       * @returns {object}
       */
      planDesignValue() {
        if (this.planDesignIsPlan) return this.cellData.plan_design_value;

        // We're matching the correct plan_design_value for this
        // component by the conainer_id stored in `this.rolledOut`
        if (this.rolledOut) {
          const planDesignIndex = this.cellData.plan_design_values.findIndex((value) => (
            value.container_id === this.rolledOut
          ));

          return this.cellData.plan_design_values[planDesignIndex];
        }

        return this.cellData.plan_design_values[0];
      },
      /**
       * Returns the validation type for this value.
       *
       * @returns {string}
       */
      validationType() {
        return this.values[this.id].validationType;
      },
      valueModel: {
        /**
         * Mapped to the values[id].value.
         *
         * @returns {string}
         */
        get() {
          return this.readonly
            ? this.planDesignValue.value
            : this.values[this.id].value;
        },
        /**
         * Triggers `updateValue()` to update the value in the store.
         *
         * @param {string|number} value
         */
        set(value) {
          const storeValue = this.readonly
            ? this.planDesignValue.value
            : this.values[this.id].value;

          // Value is getting set from the shared component on load.
          if (storeValue === value) {
            return;
          }

          this.updateValue({ value });
        },
      },
    },
    methods: {
      ...mapActions(useCallouts, [
        'addCallout',
        'markCalloutAsRead',
      ]),
      ...mapActions(useUserPreferredPdvsStore, ['editUserPreferredPdv']),
      ...mapActions(usePlanDesignStore, ['patchPlanAttribute']),
      ...mapActions(useProductStore, ['getCurrentProduct']),
      /**
       * Toggles the callout tooltip visibility and sets the read property
       * for all broker callouts to true if the callout is opened for the first time.
       */
      toggleCallout() {
        this.calloutVisible = !this.calloutVisible;

        if (this.calloutVisible) {
          this.callouts.forEach(({ id, read, broker }) => {
            if (broker && !read) {
              this.markCalloutAsRead(id, this.planDesignValue.id);
            }
          });
        }
      },
      /**
       * Updates the store values[id].value
       * Saves on blur or if `save` is passes as true.
       *
       * @param {string} value
       */
      updateValue({ value }) {
        // Debounce value updating and save
        clearTimeout(this.timer);

        this.timer = setTimeout(async () => {
          this.isInitValue = false;
          this.values[this.id].value = value;
          this.values[this.id].automated_by = {};
          // When the user updates a value we need to update planDesignErrors by emitting to the parent table
          this.$emit('validateForm');

          this.editUserPreferredPdv({
            plan_design_attribute_id: this.attributeId,
            value,
            callout_note: this.callout ? this.callout.note : null,
          });

          if (value) {
            this.patchPlanAttribute({
              id: this.id,
              productId: this.productId,
              productState: this.productState,
            });
            if (this.isUploadRenewalRatePass && this.productState === 'not_started') {
              await this.getCurrentProduct(this.productId, false);
            }
          }
        }, 50);
      },
      /**
       * Creates a new Callout. Pass the benefit callout comments flag value as an
       * argument to tell the store action to use either the new or old way of adding a callout.
       *
       * @param {string} newNote
       */
      async handleAddCallout(newNote) {
        try {
          this.addCallout(
            this.productId,
            this.planDesignValue.id,
            newNote,
          );
          this.editUserPDVState(newNote);
        } catch {
          this.$message({
            duration: 3000,
            showClose: true,
            type: 'error',
            message: 'There was a problem adding the callout.',
          });
        }
      },
      /**
       * Method to update the PPDV Store
       *
       * @param {string} note
       */
      editUserPDVState(note = null) {
        this.editUserPreferredPdv({
          plan_design_attribute_id: this.attributeId,
          value: this.valueModel,
          callout_note: note || null,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  /* stylelint-disable selector-max-compound-selectors */
  :deep() {
    .el-form-item__error {
      position: relative;
    }

    .input-blank.el-input__inner {
      border-color: var(--tf-warning);
    }

    .el-form-item.is-error {
      .el-input__inner,
      .el-textarea__inner {
        border-color: var(--tf-gray-medium);
      }

      &:not(.hide-error) {
        .el-input__inner,
        .el-textarea__inner {
          border-color: var(--tf-warning);
        }
      }
    }
  }
  /* stylelint-enable selector-max-compound-selectors */
  .warning-border {
    :deep() {
      .el-form-item__error {
        position: relative;
      }

      .el-textarea__inner {
        border-color: var(--tf-warning);
      }

      .el-form-item.highlighted {
        box-shadow: none;
      }
    }
  }

  .remove-error {
    :deep() {
      .el-form-item__error {
        display: none;
      }

      .input-blank.el-input__inner {
        border-color: var(--tf-gray-medium);
      }

      .el-form-item.highlighted {
        box-shadow: none;
      }
    }
  }

  .subtype-name {
    background: var(--tf-gray);
    color: var(--tf-white);
    margin: -15px -20px 15px;
    padding: 10px 18px;

    .cell-show-input & {
      margin: 0 -13px 13px;
    }
  }

  .plan-design-input {
    display: flex;
    align-items: center;
    width: 100%;
  }

  :deep(.el-form-item) {
    flex: 1;
  }

  .cell-show-input .callout {
    min-width: 41px;
  }

  .callout .callout-button {
    flex: none;
    margin-left: 10px;
    opacity: 0;

    &:hover {
      cursor: pointer;
    }

    .plan-design-value-cell:hover &,
    .plan-design-value-cell:focus-within &,
    &.has-callouts {
      opacity: 1;
    }
  }

  :deep(.with-close) .callout-button {
    opacity: 1;
  }

  :deep(.el-form-item__error) {
    position: relative;
  }

  .cell-read-only > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .source-value {
    color: var(--tf-gray);
    font-size: 12px;
    margin: 0 0 5px;
  }
</style>
