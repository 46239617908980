import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * GET all quote level comments by document ID.
 *
 * @param {number} documentId
 * @returns {Promise}
 */
export const getComments = (documentId) => {
  const url = `${normalizedEndpoint}/comments/document/${documentId}`;

  return new Promise((resolve, reject) => {
    axios.get(url, axiosConfig())
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * POST a new comment.
 *
 * @param {object} param
 * @param {number | Array} param.commentableIds
 * @param {string} param.content
 * @param {number} param.parentId
 * @returns {Promise}
 */
export const postComment = ({
  commentableIds,
  content,
  parentId,
}) => {
  const url = `${normalizedEndpoint}/comments`;

  return new Promise((resolve, reject) => {
    const data = {
      commentable_type: 'Document',
      content,
      parent_id: parentId,
    };

    data.commentable_id = commentableIds;

    axios.post(url, data, axiosConfig())
      .then((response) => resolve(response.data))
      .catch((e) => reject(e));
  });
};

/**
 * GET all comments by project ID and application namespace.
 *
 * @param {string} projectId
 * @returns {Promise}
 */
export const getCommentsByProject = (projectId) => {
  const endpoint = normalizedEndpoint;
  const url = `${endpoint}/comments/project/${projectId}`;

  return new Promise((resolve, reject) => {
    axios.get(url, axiosConfig())
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
