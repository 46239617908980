<template>
  <div class="table">
    <h3>{{ title }}</h3>
    <ElTable
      :data="users"
    >
      <template #empty>
        There are currently no {{ title.toLowerCase() }} contacts.
      </template>

      <ElTableColumn
        v-for="(column, index) in columns"
        :key="index"
        :prop="column.prop"
        :label="column.label"
        :sortable="column.sortable"
        :width="column.width"
      />
    </ElTable>
  </div>
</template>

<script>
  export default {
    name: 'UserTable',
    props: {
      title: {
        type: String,
        required: true,
      },
      users: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        columns: [
          {
            prop: 'name',
            label: 'Name',
            sortable: true,
            width: 250,
          },
          {
            prop: 'email',
            label: 'Email',
            sortable: true,
          },
          {
            prop: 'company',
            label: 'Company',
          },
          {
            prop: 'dateAdded',
            label: 'Date added',
            sortable: true,
            width: 125,
          },
        ],
      };
    },
  };
</script>

<style scoped lang="scss">
  .el-table {
    margin-bottom: 30px;
  }

  /* stylelint-disable selector-max-compound-selectors */
  .table {
    border-bottom: none;
    padding: 20px;

    :deep .el-table th .cell {
      color: var(--tf-primary);
    }

    :deep .el-table .ascending .sort-caret.ascending {
      border-bottom-color: var(--tf-primary);
    }

    :deep .el-table .descending .sort-caret.descending {
      border-top-color: var(--tf-primary);
    }
  }
  /* stylelint-enable selector-max-compound-selectors */

</style>
